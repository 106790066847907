import React from "react";
import Modal from "react-modal";
import logo from "../../assets/logo.png";
import EmailSignInForm from "../commun/SignInButton/EmailSignInForm/EmailSignInForm";
import FacebookSignInButton from "../commun/SignInButton/FacebookSignInButton/FacebookSignInButton";
import GoogleSignInButton from "../commun/SignInButton/GoogleSignInButton/GoogleSignInButton";
import "./AuthModal.scss";
interface AuthModalProps {
  open: boolean;
  handleClose: () => void;
}

Modal.setAppElement("#root"); // Assurez-vous de définir l'élément racine pour l'accessibilité

const AuthModal: React.FC<AuthModalProps> = ({ open, handleClose }) => {
  return (
    <Modal
      isOpen={open}
      onRequestClose={handleClose}
      className="auth-modal"
      overlayClassName="auth-modal-overlay"
    >
      <div className="modal-content">
        <div className="modal-header">
          <img src={logo} alt="logo" className="modal-logo" />
        </div>
        <h2 className="modal-title">
          Sign in or create an account to continue
        </h2>
        <div className="modal-buttons">
          <GoogleSignInButton />
          <FacebookSignInButton />
          <div className="divider"></div>
          <EmailSignInForm />
        </div>
      </div>
    </Modal>
  );
};

export default AuthModal;
