import React from "react";
import ReactDOM from "react-dom/client";
import { RouterProvider } from "react-router-dom";

import axios from "axios";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { AuthCheckProvider } from "./contexts/AuthCheckContext";
import { LoginProvider } from "./contexts/checkLoginAndModal";
import { ModalTreeProvider } from "./contexts/ModalTreeContext";
import { ModalPricingProvider } from "./contexts/PrincingModalContext";
import reportWebVitals from "./reportWebVitals";
import { router } from "./routes/route";
import "./scss/styles.scss";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
axios.defaults.withCredentials = true;
root.render(
  <React.StrictMode>
    <AuthCheckProvider>
      <LoginProvider>
        <ModalTreeProvider>
          <ModalPricingProvider>
            <RouterProvider router={router} />
            <ToastContainer />
          </ModalPricingProvider>
        </ModalTreeProvider>
      </LoginProvider>
    </AuthCheckProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
