import FlipClockCountdown from "@leenguyen/react-flip-clock-countdown";
import "@leenguyen/react-flip-clock-countdown/dist/index.css";
import React from "react";
import "./PricingCard.scss";

interface PricingCardProps {
  icon: any;
  title: string;
  price: string;
  features: string[];
  annualPlan: string;
  discount?: number;
  endDate?: Date;
}

const PricingCard: React.FC<PricingCardProps> = ({
  icon,
  title,
  price,
  features,
  discount,
  annualPlan,
  endDate,
}) => {
  // Convertir le prix en nombre pour pouvoir faire le calcul
  const originalPrice = parseFloat(price);
  const discountedPrice = discount
    ? (originalPrice * (1 - discount / 100)).toFixed(2)
    : price;

  return (
    <div className="pricing-card">
      {discount && (
        <div className="discount-ribbon">
          <span>-{discount}%</span>
        </div>
      )}
      <div className="card-header">
        <div className="icon">{icon}</div>
        <h2>{title}</h2>
      </div>

      {/* Section Prix */}
      <div className="card-price">
        {discount ? (
          <>
            <div className="timerPrincipal">
              <FlipClockCountdown
                to={new Date(endDate || "").getTime()}
                labelStyle={{
                  fontSize: 0,
                  color: "hsl(35, 65%, 75%)", // Utilisation de ta variable SCSS pour les labels
                }}
                separatorStyle={{ color: "hsl(0, 0%, 100%)", size: "6px" }} // Utilisation de ta variable SCSS pour les séparateurs
                digitBlockStyle={{
                  width: 18,
                  height: 27,
                  fontSize: 18,
                  color: "hsl(0, 0%, 100%)", // Utilisation de ta variable SCSS pour les chiffres
                }}
                labels={["D", "H", "M", "S"]} // Assurez-vous que les labels sont correctement définis
                showLabels={false} // Affiche les labels
                duration={0.5}
                renderMap={[false, true, true, true]} // Désactiver les secondes
              />
            </div>
            <div className="original-discounted">
              <span className="original-price">{originalPrice}$</span>{" "}
              {/* Prix original barré */}
              <h4>
                <span className="discounted-price">
                  {discountedPrice}$
                  <span className="annualPlan">{annualPlan}</span>
                </span>{" "}
                {/* Prix réduit */}
              </h4>
            </div>
          </>
        ) : (
          <h4>
            {price} <span className="annualPlan">{annualPlan}</span>
          </h4>
        )}
      </div>

      <div className="card-features">
        <ul>
          {features.map((feature, index) => (
            <li key={index}>
              <i className="fa fa-check" aria-hidden="true"></i>
              {feature}
            </li>
          ))}
        </ul>
      </div>
      <div className="pay-button">
        <button>Buy Now</button>
      </div>
    </div>
  );
};

export default PricingCard;
