import React, { useState } from "react";
import { useStripe, useElements, CardElement } from "@stripe/react-stripe-js";
import styled from "styled-components";
import axios from "axios";
import { AiOutlineCheckCircle } from "react-icons/ai";
import { BeatLoader } from "react-spinners";

const API_URL = process.env.REACT_APP_API_URL || "";

const PaymentForm: React.FC = () => {
    const stripe = useStripe();
    const elements = useElements();
    const [paymentSuccess, setPaymentSuccess] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [loading, setLoading] = useState(false);

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        if (!stripe || !elements) {
            return; // Stripe.js n'est pas encore chargé
        }

        const cardElement = elements.getElement(CardElement);
        if (!cardElement) {
            console.error("Card Element not found");
            return;
        }

        setLoading(true);
        setErrorMessage("");

        try {
            const { error, paymentMethod } = await stripe.createPaymentMethod({
                type: "card",
                card: cardElement,
            });

            if (error) {
                console.error("Payment failed:", error);
                setErrorMessage("Le paiement a échoué. Veuillez réessayer.");
                setLoading(false);
                return;
            }

            // Appeler l'API backend pour créer un abonnement
            const response = await axios.post(
                `${API_URL}/api/create-subscription`,
                { paymentMethodId: paymentMethod.id },
                { withCredentials: true } // Nécessaire si vous utilisez des cookies pour gérer la session
            );

            if (response.status === 200) {
                setPaymentSuccess(true);
            } else {
                setErrorMessage("La création de l'abonnement a échoué.");
            }
        } catch (error) {
            console.error("Erreur lors de la création de l'abonnement:", error);
            setErrorMessage("Une erreur est survenue. Veuillez réessayer plus tard.");
        } finally {
            setLoading(false);
        }
    };

    return (
        <FormContainer onSubmit={handleSubmit}>
            <Title>Devenez Membre Premium</Title>
            <Description>
                Profitez d'un accès complet à toutes les ouvertures d'échecs et de nombreuses autres fonctionnalités premium.
                Rejoignez-nous pour seulement <strong>8,60€mois</strong> et améliorez vos compétences aux échecs !
            </Description>
            {paymentSuccess ? (
                <SuccessMessage>
                    <AiOutlineCheckCircle size={48} />
                    <h3>Paiement réussi ! Merci pour votre abonnement Premium.</h3>
                    <p>Vous avez maintenant accès à toutes les fonctionnalités de notre site.</p>
                </SuccessMessage>
            ) : (
                <>
                    <CardElementContainer>
                        <CardElement options={CARD_OPTIONS} />
                    </CardElementContainer>
                    <PayButton type="submit" disabled={!stripe || loading}>
                        {loading ? <BeatLoader color="#ffffff" size={10} /> : "S'abonner pour 8,60$/mois"}
                    </PayButton>
                    {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
                </>
            )}
        </FormContainer>
    );
};

export default PaymentForm;

// Styling avec styled-components
const FormContainer = styled.form`
  max-width: 500px;
  margin: 50px auto;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  background-color: #ffffff;
  text-align: center;
`;

const Title = styled.h2`
  margin-bottom: 20px;
  font-size: 1.8rem;
  color: #333;
`;

const Description = styled.p`
  font-size: 1rem;
  color: #666;
  margin-bottom: 30px;
  line-height: 1.6;
  strong {
    color: #333;
  }
`;

const CardElementContainer = styled.div`
  padding: 15px;
  border: 1px solid #e0e0e0;
  border-radius: 5px;
  margin-bottom: 20px;
  background-color: #f9f9f9;
`;

const PayButton = styled.button`
  width: 100%;
  padding: 15px;
  font-size: 1rem;
  color: #ffffff;
  background-color: #28a745;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    background-color: #218838;
  }

  &:disabled {
    background-color: #cccccc;
    cursor: not-allowed;
  }
`;

const SuccessMessage = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #155724;
  padding: 20px;
  border-radius: 10px;
  background-color: #d4edda;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);

  h3 {
    margin-top: 10px;
    font-size: 1.5rem;
    color: #155724;
  }

  p {
    font-size: 1rem;
    color: #155724;
    margin-top: 10px;
  }
`;

const ErrorMessage = styled.p`
  color: #d9534f;
  margin-top: 10px;
`;

// Options de personnalisation du CardElement
const CARD_OPTIONS = {
    style: {
        base: {
            fontSize: '16px',
            color: '#424770',
            '::placeholder': {
                color: '#aab7c4',
            },
        },
        invalid: {
            color: '#9e2146',
        },
    },
};