import React, { useState } from "react";
import Flag from "react-world-flags";
import { useSiteSettingsStore } from "../../../store/useSiteSettingsStore";
import { Language } from "../../../types";
import "./LanguageDropdown.scss";

const LanguageDropdown: React.FC = () => {
  const { language, setLanguage } = useSiteSettingsStore(); // Accède à la fonction pour changer la langue
  const [isOpen, setIsOpen] = useState(false);

  const languages = [
    { code: "en", label: "en", flag: "gb" },
    { code: "fr", label: "fr", flag: "fr" },
  ];

  const changeLanguage = (lng: Language) => {
    setLanguage(lng);
    setIsOpen(!isOpen);
  };

  return (
    <div className="language-dropdown">
      <button className="dropdown-btn" onClick={() => setIsOpen(!isOpen)}>
        <Flag
          code={languages.find((l) => l.code === language)?.flag || "gb"}
          alt={language}
          className="flag-icon"
        />
      </button>

      {isOpen && (
        <ul className="dropdown-menu">
          {languages.map((lang) => (
            <li
              key={lang.code}
              onClick={() => changeLanguage(lang.code as Language)}
              className="dropdown-item"
            >
              <Flag code={lang.flag} alt={lang.label} className="flag-icon" />{" "}
              {lang.label}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default LanguageDropdown;
