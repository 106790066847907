import { OpeningDataHomePage } from "../../types";

export const OpeningInfoCarokann: OpeningDataHomePage = {
  nom: "Caro-Kann",
  createur: " asa",
  color: "b",
  category: ["Defence"],
  level: 1,
  commentaire:
    "Defense solid avec de grande possiblite de gain\nIdeal pour tout les niveaux\n",
  nbrOfPath: 90,
  history: ["e4", "d6"],
  path: "/caroKann",
};
