// LoginContext.tsx
import React, {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from "react";

import AuthModal from "../components/AuthModal/AuthModal";
import { useUserStore } from "../store/useUserStore";

// Définition des types pour le contexte
interface LoginContextProps {
  checkLoginAndModal: () => Promise<boolean>;
}

// Valeur par défaut pour le contexte
const LoginContext = createContext<LoginContextProps>({
  checkLoginAndModal: async () => true, // Valeur par défaut si le contexte n'est pas fourni
});

// Typage des props du LoginProvider
interface LoginProviderProps {
  children: ReactNode; // Les enfants rendus par ce provider, typiquement d'autres composants
}

export const LoginProvider: React.FC<LoginProviderProps> = ({ children }) => {
  const { isLogged, checkLoginStatus } = useUserStore();
  const [isModalOpen, setIsModalOpen] = useState(false);

  // Fonction pour vérifier l'état de connexion et ouvrir la modal si nécessaire
  const checkLoginAndModal = async (): Promise<boolean> => {
    if (!isLogged) {
      const isUserLogged = await checkLoginStatus();
      if (!isUserLogged) {
        setIsModalOpen(true);
        return false;
      }
    }
    return true;
  };

  useEffect(() => {
    if (isLogged) {
      // Par exemple, lorsque l'utilisateur se connecte, on peut fermer la modal
      setIsModalOpen(false);
      console.log("L'utilisateur est connecté");
    } else {
      console.log("L'utilisateur n'est pas connecté");
    }
  }, [isLogged]);
  // Fonction pour fermer la modal
  const handleClose = () => setIsModalOpen(false);

  // Fournit le contexte avec la fonction checkLoginAndModal
  return (
    <LoginContext.Provider value={{ checkLoginAndModal }}>
      {children}
      {isModalOpen && (
        <AuthModal open={isModalOpen} handleClose={handleClose} />
      )}
    </LoginContext.Provider>
  );
};

// Hook pour accéder au contexte
export const useLogin = () => useContext(LoginContext);
