import React, {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from "react";

import PricingModal from "../components/PrincingModal/PricingModal";
import { useUserStore } from "../store/useUserStore"; // Assumer que le store gère l'état premium

// Définir le type du contexte
interface ModalPricingContextProps {
  checkPremiumAndOpenModal: () => boolean;
}

// Créer le contexte avec une valeur par défaut
const ModalPricingContext = createContext<ModalPricingContextProps | undefined>(
  undefined
);

// Créer le provider du contexte
export const ModalPricingProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const { isPremium } = useUserStore(); // Accès direct à l'état premium du store
  const [isOpenModalPricing, setIsOpenModalPricing] = useState(false);

  // Vérifie si l'utilisateur est premium et ouvre la modal si nécessaire
  const checkPremiumAndOpenModal = (): boolean => {
    if (!isPremium) {
      setIsOpenModalPricing(true);
      return false;
    }
    return true;
  };

  useEffect(() => {
    if (isPremium) setIsOpenModalPricing(false);
  }, [isPremium]); // Fermeture de la modal si l'utilisateur devient premium

  // Gestion de la fermeture de la modal
  const closeModalPricing = () => setIsOpenModalPricing(false);

  return (
    <ModalPricingContext.Provider value={{ checkPremiumAndOpenModal }}>
      {children}
      {isOpenModalPricing && (
        <PricingModal
          isOpen={isOpenModalPricing}
          closeModal={closeModalPricing}
        />
      )}
    </ModalPricingContext.Provider>
  );
};

// Hook pour consommer le contexte
export const useModalPricing = () => {
  const context = useContext(ModalPricingContext);
  if (!context) {
    throw new Error("useModalTree must be used within a ModalTreeProvider");
  }
  return context;
};
