import React, { useEffect, useMemo, useRef, useState } from "react";
import Tree from "react-d3-tree";
import icons from "../../assets/svg/icons";
import { useModalTree } from "../../contexts/ModalTreeContext"; // Utilisation du contexte
import { useLessonOpeningStore } from "../../store/useLessonOpeningStore";
import TreeManager from "../../utils/TreeManager";
import ChessboardPreview from "../commun/PreviewChessboard/PreviewChessboard";
import "./OpeningVariantTree.scss";
import { renderCustomNode } from "./RenderCustomNode/renderCustomNode";

export const OpeningVariantTree: React.FC = () => {
  const {
    getOpeningTreeConverted,
    loadMoveWrapper,

    currentMove,

    infoOpeningData,
  } = useLessonOpeningStore();
  const {
    openModalTree,
    setTreeNode,
    setCurrentMove,
    setLoadMove,
    setTreeId,
    setLegende,
    setColor,
  } = useModalTree();

  const treeData = getOpeningTreeConverted();
  // Utilisation de useMemo pour améliorer la performance
  const { ancestors, targetNode } = useMemo(() => {
    if (currentMove?.history && treeData) {
      return TreeManager.findTargetNodeByHistory(treeData, currentMove.history);
    }
    return { ancestors: [], targetNode: null }; // Valeurs par défaut
  }, [treeData, currentMove?.history]);

  // Sous-arbre basé sur l'historique
  const subsetTreeData = useMemo(() => {
    if (targetNode && ancestors.length >= 0) {
      return TreeManager.buildSubsetTree(ancestors, targetNode);
    } else if (treeData) {
      return {
        ...treeData,
        isAncestorNode: false,
        children: TreeManager.getDirectChildren(treeData),
      };
    }
    return undefined;
  }, [ancestors, targetNode, treeData]);

  const handleModalTree = () => {
    const treeNode = getOpeningTreeConverted();
    const move = currentMove;
    // Encapsuler la fonction loadMove dans une autre fonction
    setTreeNode(treeNode);
    setCurrentMove(move);
    setLoadMove(() => loadMoveWrapper); // Passe la référence de `loadMoveWrapper`
    setTreeId("treecours");
    setLegende(null);
    setColor(infoOpeningData.color);
    // Ouvrir la modal
    openModalTree();
  };

  const chessboardPreviewRef = useRef<any>(null);
  const containerRef = useRef<HTMLDivElement>(null); // Ref pour le conteneur parent

  // État pour suivre les dimensions du conteneur parent
  const [containerDimensions, setContainerDimensions] = useState({
    width: 0,
    height: 0,
  });

  // Gestion de la taille du conteneur parent
  useEffect(() => {
    const handleResize = (entries: ResizeObserverEntry[]) => {
      if (entries[0].contentRect) {
        setContainerDimensions({
          width: entries[0].contentRect.width,
          height: entries[0].contentRect.height,
        });
      }
    };

    const observer = new ResizeObserver((entries) => handleResize(entries));

    if (containerRef.current) {
      observer.observe(containerRef.current);
    }

    return () => {
      if (containerRef.current) {
        observer.unobserve(containerRef.current);
      }
    };
  }, []);

  // Calcul de la translation en fonction de la hauteur et de la largeur du parent
  const translateX = containerDimensions.width / 2;
  const translateY = (containerDimensions.height - 114 + 22.5) / 2; // conteneur parrent - taille de tout les node - aussi la moitier du rayon d'aun node

  return (
    <div className="openingVariantTree" ref={containerRef}>
      <div onClick={handleModalTree} className="openingVariantTree_open">
        {icons.open}
      </div>

      {subsetTreeData && (
        <Tree
          data={subsetTreeData}
          renderCustomNodeElement={(rd3tProps) =>
            renderCustomNode(
              rd3tProps,
              chessboardPreviewRef,
              currentMove,
              loadMoveWrapper
            )
          }
          zoomable={false}
          draggable={false}
          nodeSize={{ x: 40, y: 45 }}
          separation={{ siblings: 1, nonSiblings: 1.5 }}
          translate={{ x: translateX, y: translateY }} // Utilisation des dimensions pour centrer l'arbre
          pathClassFunc={(linkData: any) => {
            const sourceNode = linkData.source;
            const targetNode = linkData.target;

            // Vérifiez les profondeurs des nœuds source et cible
            if (sourceNode.depth === 0 && targetNode.depth === 1) {
              return "link-ancestor"; // Lien vert
            }
            if (sourceNode.depth === 1 && targetNode.depth === 2) {
              return "link-active"; // Lien gris
            }
            return "link-inactive";
          }}
          orientation="vertical"
        />
      )}

      <ChessboardPreview
        ref={chessboardPreviewRef}
        color={infoOpeningData.color}
      />
    </div>
  );
};
