import React from "react";
import useCreateOpeningStore from "../../../../../store/useCreateOpeningStore";
import { TreeNode } from "../../../../../types";
import "./NodeWithNoOrManyChildren.scss";

interface NodeWithNoOrManyChildrenProps {
  nodeDatum: TreeNode;
  handleMouseEnter: (
    event: React.MouseEvent,
    history: string[],
    comment?: string
  ) => void;
  handleMouseLeave: () => void;
}

const NodeWithNoOrManyChildren: React.FC<NodeWithNoOrManyChildrenProps> = ({
  nodeDatum,
  handleMouseEnter,
  handleMouseLeave,
}) => {
  const { selectedNode, addMultiPath, setSelectedNode } =
    useCreateOpeningStore();

  // Vérifie si le nœud est actif

  let isActive = false;
  let isNoeudEnd = false;
  // 1. Si le premier nœud n'a pas de chemin et que son enfant n'a pas de chemin non plus

  const hasChildren =
    nodeDatum?.move?.children &&
    Object.keys(nodeDatum?.move?.children).length > 0;
  const firstChildKey = Object.keys(nodeDatum?.move?.children || {})[0];
  const firstChildHasNoPath =
    nodeDatum?.move?.children[firstChildKey]?.path?.length;

  if (
    nodeDatum?.move?.history.length === 0 &&
    hasChildren &&
    firstChildHasNoPath >= 1
  ) {
    isActive = true; // Le premier nœud est inactif car son enfant n'a pas de chemin
  }

  // 2. Si le nœud a plusieurs enfants, vérifie si au moins un chemin a une longueur supérieure ou égale au nombre d'enfants
  else if (
    hasChildren &&
    Object.values(nodeDatum?.move?.children).every(
      (child) => child?.path?.length > 0
    )
  ) {
    isActive = true;
  }

  // 3. Si le nœud n'a pas d'enfants, il est actif si son chemin a une longueur > 0
  else if (!hasChildren && nodeDatum?.move?.path.length > 0) {
    isActive = true;
  } else if (!hasChildren && nodeDatum?.move?.path.length === 0) {
    isNoeudEnd = true;
  }

  const handleClick = () => {
    if (selectedNode) {
      addMultiPath(selectedNode, nodeDatum);
      setSelectedNode(null);
    } else {
      if (!isNoeudEnd) setSelectedNode(nodeDatum);
    }
  };

  return (
    <g
      // Applique la classe "active" ou "inactive" en fonction de l'état du nœud
      className={`nodeWithNoOrManyChildren ${
        isActive ? "active" : isNoeudEnd ? "inactive" : "noeudEnd"
      }`}
      onClick={isActive ? () => {} : (e) => handleClick()}
      onMouseEnter={(e) => handleMouseEnter(e, nodeDatum?.move?.history || [])}
      onMouseLeave={handleMouseLeave}
    >
      <circle cx="0" cy="0" r="12" strokeWidth="1" />
      <text
        x="0"
        y="0"
        textAnchor="middle"
        fontSize="10"
        fill="white"
        fontWeight="normal"
        dominantBaseline="central"
      >
        {nodeDatum.name}
      </text>
    </g>
  );
};

export default NodeWithNoOrManyChildren;
