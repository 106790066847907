import { useEffect, useState } from "react";
// Importer Helmet pour ajouter les balises SEO
import { Helmet } from "react-helmet";
import ChessboardCoursEntrainement from "../../components/ChessboardCoursEntrainement/ChessboardCoursEntrainement";
import Header from "../../components/commun/header/header";

import { useParams } from "react-router-dom";
import LoaderChess from "../../components/commun/LoaderChess/LoaderChess";

import { CommentCarokann } from "../../mock/CaroKann/CommentCarokann";
import { DataCaroKann } from "../../mock/CaroKann/OpeningDataCaroKann";
import { OpeningInfoCarokann } from "../../mock/CaroKann/OpeningInfoCarokann";
import { useLessonOpeningStore } from "../../store/useLessonOpeningStore";
import "./LearnTraining.scss";

export default function LearnTraining() {
  const { openingPath } = useParams(); // Récupère le paramètre d'URL

  const [loading, setLoading] = useState(true);

  const { setOpeningTree, setInfoOpeningData, infoOpeningData, setComment } =
    useLessonOpeningStore();

  useEffect(() => {
    // Simuler une requête pour récupérer les données de l'ouverture avec un délai
    const fetchOpeningData = async () => {
      setLoading(true);
      setTimeout(() => {
        const data = DataCaroKann;

        if (data) {
          setOpeningTree(data);
          setInfoOpeningData(OpeningInfoCarokann);
          setComment(CommentCarokann);
        }
        setLoading(false);
      }, 2000); // Simuler un délai de 2 secondes
    };

    fetchOpeningData();
  }, [openingPath]);

  if (loading) {
    return <LoaderChess />;
  }

  // Titre dynamique basé sur l'ouverture
  const pageTitle = `Learn ${infoOpeningData.nom} - Chess Openings`;

  return (
    <div className="learnTraining">
      {/* SEO avec Helmet */}
      <Helmet>
        <title>{pageTitle}</title>
        <meta name="description" content={infoOpeningData.commentaire} />
        <meta
          name="keywords"
          content={`chess, opening, learn, ${infoOpeningData.nom}, chess training`}
        />
        <meta property="og:title" content={pageTitle} />
        <meta
          property="og:description"
          content={`Detailed guide and training for the ${infoOpeningData.nom} opening in chess.`}
        />
      </Helmet>
      {/* <SidebarCustom /> */}
      <Header />
      <div className="learnTraining_content">
        <ChessboardCoursEntrainement />
      </div>
    </div>
  );
}
