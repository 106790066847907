import { useEffect, useState } from "react";
import { useLogin } from "../../../contexts/checkLoginAndModal";
import { useAnnotationsStore } from "../../../store/useAnnotationsStore";
import { useLessonOpeningStore } from "../../../store/useLessonOpeningStore";
import { OpeningMove } from "../../../types";
import { fireConfetti } from "../../../utils/confetti";
import ChessboardManager from "../ChessboardManager/ChessboardManager";
import "./ChessBoardCours.scss";

export default function ChessboardCours() {
  const { mode } = useLessonOpeningStore();

  // État pour le nombre d'erreurs (spécifique au mode graduation)
  const [errorCount, setErrorCount] = useState(0);
  // État pour mesurer le temps moyen entre chaque coup joué (spécifique au mode graduation)
  const [moveTimes, setMoveTimes] = useState<number[]>([]);
  const [lastMoveTime, setLastMoveTime] = useState<number | null>(null);

  const { checkLoginAndModal } = useLogin();
  const { removeAllAnnotations, addAnnotation } = useAnnotationsStore();
  const {
    currentMove,
    gameChess,
    getNextMoves,
    loadMove,
    infoOpeningData,
    currentMoveEntrainement,
    currentPage,
    setCurrentPage,
  } = useLessonOpeningStore();

  const loadMoveAndCurrentPage = (move: OpeningMove) => {
    const nextPath = move.path.find((value) => value >= currentPage);
    if (nextPath) {
      setCurrentPage(nextPath);
    } else {
      setCurrentPage(move.path[0]);
    }
    loadMove(move);
  };

  const onDrop = (sourceSquare: string, targetSquare: string) => {
    const san = gameChess.getSANFromMove(sourceSquare, targetSquare);
    if (!san) return false;

    const nextMoves = getNextMoves(currentMove?.history);

    const matchingMove = nextMoves?.find((move) => move.san === san);

    if (matchingMove && matchingMove.blockEntrainement === false) {
      // Si le coup est correct
      if (mode === "graduation") {
        // Mettre à jour le temps du dernier mouvement pour le mode graduation
        const currentTime = Date.now();
        if (lastMoveTime !== null) {
          const timeDifference = (currentTime - lastMoveTime) / 1000; // Convertir en secondes
          setMoveTimes((prevTimes) => [...prevTimes, timeDifference]);
        }
        setLastMoveTime(currentTime);
      }
      loadMoveAndCurrentPage(matchingMove);
      return true;
    }

    // Si le coup est incorrect
    addAnnotation(sourceSquare, "error");
    addAnnotation(targetSquare, "error");

    if (mode === "graduation") {
      setErrorCount((prevCount: number) => prevCount + 1);
    }

    return false;
  };

  const makeOpponentMove = (lastMove: OpeningMove) => {
    const nextMoves = getNextMoves(lastMove.history);
    if (!nextMoves.length) {
      if (currentMoveEntrainement) {
        setTimeout(() => loadMoveAndCurrentPage(currentMoveEntrainement), 500);
      } else {
        fireConfetti(); // Fin de la graduation ou de l'entraînement
      }
      return false;
    }

    const opponentMove =
      nextMoves[Math.floor(Math.random() * nextMoves.length)];
    setTimeout(() => loadMoveAndCurrentPage(opponentMove), 500);
    return true;
  };

  const handleOpponentTurn = () => {
    const playerColor = infoOpeningData.color;
    const currentPlayerColor = gameChess.getCurrentPlayerColor();
    if (playerColor !== currentPlayerColor && currentMove) {
      const result = makeOpponentMove(currentMove);
      if (!result && currentMoveEntrainement) {
        setTimeout(() => loadMoveAndCurrentPage(currentMoveEntrainement), 500);
      }
    }
  };

  useEffect(() => {
    if (mode !== "cours") {
      handleOpponentTurn();
    }
  }, [mode, currentMove]);

  // Calculer le temps moyen entre les coups (spécifique au mode graduation)
  const averageMoveTime =
    moveTimes.length > 0
      ? (
          moveTimes.reduce((a: number, b: number) => a + b, 0) /
          moveTimes.length
        ).toFixed(2)
      : "N/A";

  return (
    <>
      {mode === "graduation" && (
        <div
          style={{
            position: "absolute",
            top: "10px",
            right: "10px",
            backgroundColor: "rgba(255, 255, 255, 0.8)",
            padding: "10px",
            borderRadius: "5px",
          }}
          className="graduation-info"
        >
          <p>Nombre d'erreurs : {errorCount}</p>
          <p>Temps moyen par coup : {averageMoveTime} secondes</p>
        </div>
      )}
      <ChessboardManager
        gameChess={gameChess}
        customArrows={mode === "cours" ? currentMove.arrows : []}
        arePiecesDraggable={mode !== "cours"}
        onPieceDrop={mode === "cours" ? undefined : onDrop}
        boardOrientation={infoOpeningData.color === "w" ? "white" : "black"}
        resizable={false}
        allowAnnotations={mode === "cours"}
        currentMove={currentMove}
      />
    </>
  );
}
