import { CommentLanguage, OpeningDataHomePage, OpeningMove } from "./types";

// Objet OpeningMove vide avec toutes les clés initialisées
const emptyOpeningMove: OpeningMove = {
  evaluation: "good", // Chaîne vide pour l'évaluation
  arrows: [], // Tableau vide pour les flèches
  circles: [], // Tableau vide pour les cercles
  history: [], // Tableau vide pour l'historique
  san: "", // Chaîne vide pour SAN (notation algébrique standard)
  fen: "rnbqkbnr/pppppppp/8/8/8/8/PPPPPPPP/RNBQKBNR w KQkq - 0 1",
  path: [], // Tableau vide pour le chemin (path)
  blockEntrainement: false,
  children: {}, // Objet vide pour les enfants (prochaines possibilités)
};

const infoOpeningData: OpeningDataHomePage = {
  nom: "",
  createur: "",
  level: 1,
  color: "w",
  nbrOfPath: 0,
  commentaire: "",
  category: [],
  history: [],
  path: "",
};

export const config: {
  nbrMoveForConnect: number;
  nbrMoveForPremium: number;
  language: keyof CommentLanguage;
  localStorageCreateOpening: "opening-create-storage";
  emptyOpeningMove: OpeningMove;
  emptyInfoOpeningData: OpeningDataHomePage;
} = {
  nbrMoveForConnect: 10,
  nbrMoveForPremium: 50,
  language: "fr", // ou "fr"
  localStorageCreateOpening: "opening-create-storage",
  emptyOpeningMove: emptyOpeningMove,
  emptyInfoOpeningData: infoOpeningData,
};
