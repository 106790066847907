import { useLessonOpeningStore } from "../../store/useLessonOpeningStore";
import ProgressBarComponent from "../commun/ProgressBar/ProgressBarComponent";
import { OpeningVariantTree } from "../TreeOpening/OpeningVariantTree";
import MoveDetails from "./MoveDetails/MoveDetails";
import MoveNoDetails from "./MoveNoDetails/MoveNoDetails";
import "./TreeAndMoveDetails.scss";

export default function TreeAndMoveDetails() {
  const { mode, currentPage, infoOpeningData } = useLessonOpeningStore();

  return (
    <>
      {mode === "cours" ? (
        <div className="treeAndMoveDetails">
          <div className="treeAndMoveDetails_tree">
            <OpeningVariantTree />
          </div>
          <div className="divider"></div>
          <div className="treeAndMoveDetails_comment">
            <MoveDetails />
          </div>
          <ProgressBarComponent
            percentage={(currentPage / infoOpeningData.nbrOfPath) * 100}
          />
        </div>
      ) : (
        <div className="noDetails">
          <MoveNoDetails />
        </div>
      )}
    </>
  );
}
