import React, { useCallback, useEffect, useRef, useState } from "react";
import ReactDOM from "react-dom";
import { evaluation } from "../../../assets/svg/evaluation";
import { useAnnotationsStore } from "../../../store/useAnnotationsStore";

import { OpeningMove } from "../../../types";
import { GameChess } from "../../../utils/GameChess";
import ChessboardSizeHandler from "../ChessboardSizeHandler/ChessboardSizeHandler";
import "./ChessboardManager.scss";

interface ChessboardManagerProps {
  gameChess: GameChess;
  customArrows: any;
  arePiecesDraggable: boolean;
  onPieceDrop?: (sourceSquare: string, targetSquare: string) => boolean;
  boardOrientation: "white" | "black";
  resizable?: true | false;
  allowAnnotations?: boolean; // Prop pour activer/désactiver les annotations
  enableClickHandling?: boolean; // Prop pour activer/désactiver la gestion des clics
  enableScrollOnPieces?: boolean; // Nouvelle prop pour activer/désactiver le scroll sur les pièces
  createArrow?: boolean;
  currentMove?: OpeningMove;
}

const ChessboardManager: React.FC<ChessboardManagerProps> = ({
  gameChess,
  customArrows,
  arePiecesDraggable,
  onPieceDrop,
  boardOrientation,
  currentMove,
  resizable = true,
  allowAnnotations = true, // Pa?r défaut, les annotations sont activées
  enableClickHandling = true, // Par défaut, la gestion des clics est activée
  enableScrollOnPieces = true, // Par défaut, le scroll sur les pièces est activé
  createArrow = true,
}) => {
  const [clickedSquare, setClickedSquare] = useState<string | null>(null);
  const [annotatedSquares, setAnnotatedSquares] = useState<string[]>([]);

  const {
    addAnnotation,
    removeAnnotationByPosition,
    generateCustomSquareStyles,
    removeAllAnnotations,
  } = useAnnotationsStore();

  const removeCircleAnnotations = useCallback(() => {
    setAnnotatedSquares((prevAnnotatedSquares) => {
      prevAnnotatedSquares.forEach((square) => {
        removeAnnotationByPosition(square);
      });
      return [];
    });
  }, [removeAnnotationByPosition]);

  const handleSquareClick = (square: string) => {
    if (!enableClickHandling || !onPieceDrop) {
      return; // Si la gestion des clics est désactivée ou si onPieceDrop n'existe pas, ne rien faire
    }

    console.log(clickedSquare);
    const legalMoves = gameChess.getLegalMoves(); // Récupérer tous les coups légaux
    const boardState = gameChess.getPiecesOnBoard(); // Obtenir l'état du plateau

    const pieceAtTarget = boardState[square]; // Pièce sur la case cible
    const pieceAtSource = clickedSquare ? boardState[clickedSquare] : null; // Pièce sur la case source

    if (clickedSquare) {
      const isSameColorPiece =
        pieceAtTarget &&
        pieceAtSource &&
        pieceAtTarget.color === pieceAtSource.color; // Vérifier si les pièces appartiennent à la même équipe

      if (
        pieceAtTarget &&
        pieceAtTarget.color === gameChess.getCurrentPlayerColor()
      ) {
        removeCircleAnnotations();
        setClickedSquare(square);
        const newAnnotatedSquares: string[] = [];
        legalMoves
          .filter((move) => move.from === square)
          .forEach((move) => {
            addAnnotation(move.to, "legalMove", "hsl(34, 78%, 60%)");

            newAnnotatedSquares.push(move.to);
          });
        setAnnotatedSquares(newAnnotatedSquares);
      } else if (isSameColorPiece) {
        console.log(
          `Impossible de déplacer sur la case ${square}, une de vos pièces l'occupe`
        );
      } else {
        const isLegalMove = legalMoves.some(
          (move) => move.from === clickedSquare && move.to === square
        );

        if (isLegalMove) {
          if (onPieceDrop) {
            removeCircleAnnotations();
            onPieceDrop(clickedSquare, square);
            setClickedSquare(null); // Réinitialiser après un coup valide
          }
        } else {
          console.log(
            `Le mouvement de ${clickedSquare} vers ${square} n'est pas légal`
          );
        }
      }
    } else {
      if (
        pieceAtTarget &&
        pieceAtTarget.color === gameChess.getCurrentPlayerColor()
      ) {
        setClickedSquare(square);
        const newAnnotatedSquares: string[] = [];
        legalMoves
          .filter((move) => move.from === square)
          .forEach((move) => {
            addAnnotation(move.to, "legalMove", "hsl(34, 78%, 60%)");

            newAnnotatedSquares.push(move.to);
          });
        setAnnotatedSquares(newAnnotatedSquares);
      } else {
        console.log(
          `La case ${square} n'est pas une case valide pour commencer ou ce n'est pas votre pièce`
        );
      }
    }
  };

  useEffect(() => {
    removeAllAnnotations();
    if (currentMove) {
      const moves = gameChess.getPreviousMove();
      if (moves) {
        addAnnotation(moves.from, "success");
        addAnnotation(moves.to, "success");
      }

      currentMove.circles.forEach(([square, type]) => {
        addAnnotation(square, "circle", type);
      });
    }
  }, [currentMove]);

  // Fonction pour désactiver "touch-action: none" sur les pièces
  const enableScrollOnPiecesFunc = useCallback(() => {
    const pieces = document.querySelectorAll("[data-piece]"); // Chercher les pièces sur le DOM

    pieces.forEach((piece) => {
      (piece as HTMLElement).style.touchAction = "auto"; // Désactiver touch-action: none
    });
  }, []);

  useEffect(() => {
    if (!enableScrollOnPieces) {
      return; // Ne rien faire si le scroll sur les pièces est désactivé
    }

    enableScrollOnPiecesFunc(); // Activer le scroll après le rendu du composant

    // Observer les modifications liées aux pièces (ajout/suppression)
    const observer = new MutationObserver(() => {
      enableScrollOnPiecesFunc(); // Réappliquer le style lorsque le DOM change
    });

    const chessboard = document.querySelector(".chessboard-wrapper"); // Sélectionner le conteneur du plateau
    if (chessboard) {
      observer.observe(chessboard, {
        childList: true,
        attributes: true,
        subtree: true,
      });
    }

    return () => observer.disconnect(); // Nettoyage à la fin du cycle de vie du composant
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [[], enableScrollOnPieces, enableScrollOnPiecesFunc]);

  const annotationsRef = useRef<HTMLElement[]>([]);

  useEffect(() => {
    annotationsRef.current.forEach((annotation) => {
      if (annotation.parentNode) {
        annotation.parentNode.removeChild(annotation);
      }
    });

    annotationsRef.current = [];

    if (currentMove?.san) {
      const targetSquare = currentMove.san.slice(-2);
      const squareElement = document.querySelector(
        `[data-square='${targetSquare}']`
      ) as HTMLElement;

      if (
        squareElement &&
        currentMove.evaluation &&
        evaluation[currentMove.evaluation]
      ) {
        squareElement.style.position = "relative";

        const svgWrapper = document.createElement("div");
        svgWrapper.classList.add("custom-child");
        svgWrapper.style.position = "absolute";
        svgWrapper.style.right = "-5px";
        svgWrapper.style.top = "-5px";
        svgWrapper.style.height = "16px";
        svgWrapper.style.width = "16px";
        svgWrapper.style.zIndex = "1000";

        // Vérification que `evaluation[currentMove.evaluation]` est bien un `ReactElement`
        const evaluationElement = evaluation[currentMove.evaluation];
        if (React.isValidElement(evaluationElement)) {
          // Insérer le composant React (SVG) dans l'élément DOM
          ReactDOM.render(evaluationElement, svgWrapper);

          // Ajouter l'élément SVG à la case sélectionnée
          squareElement.appendChild(svgWrapper);

          // Garder une trace de cette annotation dans useRef
          annotationsRef.current.push(svgWrapper);
        }
      }
    }

    return () => {
      annotationsRef.current.forEach((annotation) => {
        if (annotation.parentNode) {
          annotation.parentNode.removeChild(annotation);
        }
      });
      annotationsRef.current = [];
    };
  }, [currentMove]);

  return (
    <ChessboardSizeHandler
      position={gameChess.getBoardPosition()}
      customArrows={customArrows}
      arePiecesDraggable={arePiecesDraggable}
      onPieceDrop={onPieceDrop}
      onSquareClick={handleSquareClick}
      boardOrientation={boardOrientation}
      resizable={resizable}
      customSquareStyles={generateCustomSquareStyles()}
      createArrow={createArrow}
    />
  );
};

export default ChessboardManager;
