import React, { useEffect, useState } from "react";
import icons from "../../../assets/svg/icons"; // Assure-toi que icons.stop est un élément SVG
import useCreateOpeningStore from "../../../store/useCreateOpeningStore";

interface LinkIconProps {
  transformedX: number;
  transformedY: number;
  iconSize: number;
  history: string[];
}

const LinkIcon: React.FC<LinkIconProps> = ({
  transformedX,
  transformedY,
  iconSize,
  history,
}) => {
  const { updateBlockEntrainement, openingManager } = useCreateOpeningStore();

  const [visible, setVisible] = useState<boolean>(false); // Initialisation à `false`

  useEffect(() => {
    const isBlock = openingManager.getMoveDetails(history)?.blockEntrainement;
    console.log(openingManager.getMoveDetails(history), isBlock);
    if (isBlock) setVisible(isBlock);
  }, []);

  // Gestion du clic pour alterner l'état `selected` et mettre à jour `blockEntrainement`
  const handleClick = (): void => {
    setVisible((visible) => {
      const newVisible = !visible;

      // Récupérer les détails
      const moveDetails = openingManager.getMoveDetails(history);
      if (moveDetails) {
        updateBlockEntrainement(moveDetails, newVisible);
        // Rendre l'icône invisible par défaut
      }

      return newVisible; // Retourner la nouvelle valeur de `selected`
    });
  };

  return (
    <>
      <circle
        cx={transformedX}
        cy={transformedY}
        r={14}
        fill="transparent"
        style={{ cursor: "pointer", outline: "none" }}
        tabIndex={-1} // Empêche le focus
        onClick={handleClick} // Gérer le clic pour alterner l'état sélectionné
      />
      {/* Afficher l'icône uniquement si visible est vrai */}
      {visible && (
        <g
          transform={`translate(${transformedX - iconSize / 2}, ${
            transformedY - iconSize / 2
          })`}
          style={{ pointerEvents: "none" }} // Assure que les événements passent au cercle
        >
          {icons.stop}
        </g>
      )}
    </>
  );
};

export default LinkIcon;
