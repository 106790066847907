import { create } from "zustand";
import { config } from "../config";
import { mockOpeningEmptyData } from "../mock/mockOpeninEmptyData";
import {
  Language,
  ModeLesson,
  OpeningDataHomePage,
  OpeningMove,
  TreeNode,
  TreeOpeningData,
} from "../types";
import { GameChess } from "../utils/GameChess";
import { OpeningManager } from "../utils/OpeningManager";
import TreeManager from "../utils/TreeManager";

// Définition du store Zustand avec la couleur ajoutée
interface LessonOpeningStoreState {
  openingManager: OpeningManager;
  setOpeningTree: (newOpeningTree: TreeOpeningData) => void;

  getOpeningTree: () => OpeningMove;
  getOpeningTreeConverted: () => TreeNode | null;

  //Mode de la lesson cours entrainement evaluation
  mode: ModeLesson;
  currentMoveEntrainement: OpeningMove | null;
  toggleMode: () => void;
  setMode: (mode: ModeLesson) => void;
  // Gestion de la navigation
  currentPage: number;
  setCurrentPage: (page: number) => void;
  currentMove: OpeningMove;
  treeOpeningData: TreeOpeningData;
  loadMove: (moveDetail: OpeningMove) => void;
  loadMoveWrapper: (moveDetail: OpeningMove) => void;

  setCurrentMove: (move: OpeningMove) => void;
  getNextMoves: (history: string[] | undefined) => OpeningMove[];
  getNextMoveByPage: (currentPage: number) => OpeningMove | null;
  getPreviousMoveByPage: (currentPage: number) => OpeningMove | null;
  getMoveDetails: (history: string[]) => OpeningMove | null;
  getMoveByPage: (page: number) => OpeningMove | null;
  handleUndoMove: () => void;
  handleNextMove: () => void;

  // Gestion des commentaires
  comments: { [fen: string]: { [lang: string]: string } };
  setComment: (comments: { [fen: string]: { [lang: string]: string } }) => void;
  getComment: (fen: string, lang: Language) => string | null;

  // Information sur l'ouverture
  infoOpeningData: OpeningDataHomePage;
  setInfoOpeningData: (InfoOpening: OpeningDataHomePage) => void;

  // Informations sur les éléments du chessboard
  gameChess: GameChess;
  resetGame: () => void;
}

// Création du store Zustand avec les fonctions vides
export const useLessonOpeningStore = create<LessonOpeningStoreState>(
  (set, get) => ({
    openingManager: new OpeningManager(mockOpeningEmptyData),
    currentMove: config.emptyOpeningMove,
    currentPage: 0,

    //Mode de la lesson cours entrainement evaluation
    mode: "cours",
    currentMoveEntrainement: null,

    treeOpeningData: mockOpeningEmptyData,
    // Informations sur l'ouverture
    infoOpeningData: config.emptyInfoOpeningData,

    // Informations sur les éléments du chessboard
    gameChess: new GameChess(),

    // Gestion des commentaires
    comments: {},
    setComment: (comments: { [fen: string]: { [lang: string]: string } }) => {
      set({
        comments: comments,
      });
    },
    // Fonction pour changer l'ouverture
    setOpeningTree: (newTreeOpeningData: TreeOpeningData) => {
      set({
        openingManager: new OpeningManager(newTreeOpeningData),
      });
    },

    // Fonction pour obtenir l'arbre d'ouverture
    getOpeningTree: () => {
      return get().openingManager.getOpeningTree();
    },

    // Fonction pour convertir l'arbre d'ouverture
    getOpeningTreeConverted: () => {
      const tree = get().openingManager.getOpeningTree();

      return TreeManager.convertToTreeData(tree);
    },

    toggleMode: () =>
      set((state) => ({
        mode: state.mode === "cours" ? "entrainement" : "cours",
      })),

    // Mettre à jour le mode et initialiser la page d'entraînement à chaque passage en mode "entrainement"
    setMode: (mode: ModeLesson) =>
      set(() => {
        // Récupérer la page actuelle depuis useMoveNavigationStore
        const currentMoveChessStore = get().currentMove;

        return {
          mode,
          currentMoveEntrainement:
            mode === "entrainement" ? currentMoveChessStore : null,
        };
      }),

    loadMove: (moveDetail: OpeningMove) => {
      get().gameChess.loadPosition(moveDetail.history);
      set({ currentMove: moveDetail });
    },

    loadMoveWrapper: (moveDetail: OpeningMove) => {
      get().setCurrentPage(moveDetail.path[0]);
      get().loadMove(moveDetail); // Appelle la vraie fonction `loadMove` quand nécessaire
    },

    setCurrentMove: (move: OpeningMove) => {
      set({ currentMove: move });
    },

    getNextMoves: (history: string[] | undefined) => {
      const openingManager = get().openingManager;
      return openingManager.getNextMoves(history);
    },

    getNextMoveByPage: (currentPage: number) => {
      const openingManager = get().openingManager;
      return openingManager.getNextMoveByPage(currentPage);
    },

    getPreviousMoveByPage: (currentPage: number) => {
      const openingManager = get().openingManager;
      return openingManager.getPreviousMoveByPage(currentPage);
    },

    getMoveDetails: (history: string[]) => {
      const openingManager = get().openingManager;
      return openingManager.getMoveDetails(history);
    },

    getMoveByPage: (page: number) => {
      const openingManager = get().openingManager;
      return openingManager.getMoveByPage(page);
    },

    setCurrentPage: (page) => set({ currentPage: page }),

    handleUndoMove: () => {
      const { currentPage, loadMove, setCurrentPage, getMoveByPage } = get();
      const previousPage = currentPage - 1;

      if (previousPage >= 0) {
        const undoMoveDetail = getMoveByPage(previousPage);
        if (undoMoveDetail) {
          loadMove(undoMoveDetail);
          setCurrentPage(previousPage);
        }
      }
    },

    handleNextMove: () => {
      const { currentPage, loadMove, setCurrentPage, getMoveByPage } = get();

      const nextPage = currentPage + 1;

      const nextMoveDetail = getMoveByPage(nextPage);
      if (nextMoveDetail) {
        loadMove(nextMoveDetail);
        setCurrentPage(nextPage);
      }
    },

    getComment: (fen: string, lang: Language) => {
      const comments = get().comments[fen];

      // Si le commentaire existe pour la langue spécifiée, le retourner
      if (comments && comments[lang]) {
        return comments[lang];
      }

      // Si le commentaire n'existe pas pour la langue spécifiée, rechercher l'anglais
      if (comments && comments["en"]) {
        return comments["en"];
      }

      // Si l'anglais n'existe pas, retourner la première langue disponible
      const fallbackLang = Object.keys(comments || {})[0]; // Prendre la première langue disponible
      if (fallbackLang) {
        return comments[fallbackLang];
      }

      // Si aucun commentaire n'est disponible pour cette position
      return null;
    },

    setInfoOpeningData: (infoOpening: OpeningDataHomePage) => {
      set({ infoOpeningData: infoOpening });
    },

    resetGame: () => {
      get().gameChess.reset();
    },
  })
);
