import { useEffect, useRef, useState } from "react";
import LazyLoad from "react-lazyload"; // Importer LazyLoad

import { useSearchOpening } from "../../../store/useSearchOpening";
import OpeningCard from "../OpeningCard/OpeningCard";
import "./OpeningGrid.scss";

const ITEMS_PER_LOAD = 12; // Limite d'éléments par chargement

const OpeningGrid = () => {
  const [currentItemsCount, setCurrentItemsCount] = useState(ITEMS_PER_LOAD); // Combien d'éléments afficher
  const { filteredOpenings } = useSearchOpening();
  const contentRef = useRef<HTMLDivElement>(null); // Référence à la div "contentHomePage"

  // Fonction pour charger plus d'éléments
  const loadMoreItems = () => {
    if (currentItemsCount < filteredOpenings.length) {
      setCurrentItemsCount((prevCount) => prevCount + ITEMS_PER_LOAD);
    }
  };

  // useEffect pour détecter quand l'utilisateur est proche du bas de la page
  useEffect(() => {
    const handleScroll = () => {
      if (contentRef.current) {
        const contentRect = contentRef.current.getBoundingClientRect();

        // Comparer la taille du viewport et la position du bas de la div "contentHomePage"
        if (window.innerHeight >= contentRect.bottom - 1) {
          loadMoreItems(); // Charger plus d'éléments
        }
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [currentItemsCount, filteredOpenings.length]);

  // Obtenir les éléments à afficher
  const currentItems = filteredOpenings.slice(0, currentItemsCount);

  return (
    <>
      <div className="opening-grid" ref={contentRef}>
        {currentItems.map((opening, index) => (
          <LazyLoad key={index} height={350} offset={-50}>
            <OpeningCard dataOpening={opening} />
          </LazyLoad>
        ))}
      </div>
    </>
  );
};

export default OpeningGrid;
