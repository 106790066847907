import { CiRoute } from "react-icons/ci";
import {
  FaCheckCircle,
  FaEraser,
  FaPaperPlane,
  FaTimesCircle,
  FaTrash,
} from "react-icons/fa";
import { LiaFileDownloadSolid } from "react-icons/lia";
import "./Actions.scss";

interface ActionsProps {
  handleAddRoute: () => void;
  onClear: () => void;
  onDelete: () => void;
  isConfirmed: boolean;
  onConfirm: () => void;
  onCancel: () => void;
  onSendServ: () => void;
  handleDownloadFiles: () => void;
}

export default function Actions({
  handleAddRoute,
  onClear,
  onDelete,
  isConfirmed,
  onConfirm,
  onCancel,
  onSendServ,
  handleDownloadFiles,
}: ActionsProps) {
  return (
    <div className="actions">
      <div className="basic-actions">
        <button onClick={handleAddRoute} title="addRoute">
          <CiRoute />
        </button>

        <button onClick={onClear} title="Clear">
          <FaEraser />
        </button>
        <button onClick={onDelete} title="Supprimer">
          <FaTrash />
        </button>
      </div>

      {isConfirmed ? (
        <div className="send-actions">
          <p>Êtes-vous sûr de vouloir envoyer les données au serveur ?</p>
          <button onClick={onSendServ} title="Confirmer l'envoi">
            <FaCheckCircle />
          </button>
          <button onClick={onCancel} title="Annuler">
            <FaTimesCircle />
          </button>
        </div>
      ) : (
        <>
          <div className="sendDownload">
            <div className="send-button">
              <button onClick={onConfirm} title="Envoyer au serveur">
                <FaPaperPlane />
              </button>
            </div>

            <div className="download-button">
              <button onClick={handleDownloadFiles}>
                <LiaFileDownloadSolid />
              </button>
            </div>
          </div>
        </>
      )}
    </div>
  );
}
