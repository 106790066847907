// CreateOpening.jsx

import ChessboardCreation from "../../components/commun/ChessboardCreation/ChessboardCreation";
import PannelCreateOpening from "../../components/PannelCreateOpening/PannelCreateOpening";

import "./CreateOpening.scss";
import OpeningTree from "./OpeningTree/OpeningTree";

export default function CreateOpening() {
  return (
    <div className="create-opening-container">
      <PannelCreateOpening />

      <ChessboardCreation />

      {/* Arbre d'Ouvertures */}
      <OpeningTree />
    </div>
  );
}
