import { TreeOpeningData } from "../../types";

export const DataCaroKann: TreeOpeningData = {
  tree: {
    evaluation: "",
    san: "",
    arrows: [],
    history: [],
    circles: [],
    blockEntrainement: false,
    path: [0],
    children: {
      e4: {
        san: "e4",
        evaluation: "good",
        arrows: [],
        circles: [],
        blockEntrainement: false,
        history: ["e4"],
        path: [1],
        children: {
          c6: {
            san: "c6",
            evaluation: "good",
            arrows: [],
            circles: [],
            blockEntrainement: false,
            history: ["e4", "c6"],
            path: [2],
            children: {
              d4: {
                san: "d4",
                evaluation: "good",
                arrows: [],
                circles: [],
                blockEntrainement: false,
                history: ["e4", "c6", "d4"],
                path: [3],
                children: {
                  d5: {
                    san: "d5",
                    evaluation: "good",
                    arrows: [["d5", "e4", "#C72A2A"]],
                    circles: [],
                    blockEntrainement: false,
                    history: ["e4", "c6", "d4", "d5"],
                    path: [4, 25, 44, 59, 64],
                    children: {
                      Nc3: {
                        san: "Nc3",
                        evaluation: "good",
                        arrows: [
                          ["c3", "e4", "#C72A2A"],
                          ["d5", "e4", "#C72A2A"],
                        ],
                        circles: [],
                        blockEntrainement: false,
                        history: ["e4", "c6", "d4", "d5", "Nc3"],
                        path: [5],
                        children: {
                          dxe4: {
                            san: "dxe4",
                            evaluation: "good",
                            arrows: [],
                            circles: [],
                            blockEntrainement: false,
                            history: ["e4", "c6", "d4", "d5", "Nc3", "dxe4"],
                            path: [6],
                            children: {
                              Nxe4: {
                                san: "Nxe4",
                                evaluation: "good",
                                arrows: [
                                  ["e4", "f6", "#C72A2A"],
                                  ["e4", "g5", "#C72A2A"],
                                  ["e4", "d6", "#C72A2A"],
                                  ["e4", "c5", "#C72A2A"],
                                ],
                                circles: [],
                                blockEntrainement: false,
                                history: [
                                  "e4",
                                  "c6",
                                  "d4",
                                  "d5",
                                  "Nc3",
                                  "dxe4",
                                  "Nxe4",
                                ],
                                path: [7],
                                children: {
                                  Bf5: {
                                    san: "Bf5",
                                    evaluation: "good",
                                    arrows: [["f5", "e4", "#C72A2A"]],
                                    circles: [],
                                    blockEntrainement: false,
                                    history: [
                                      "e4",
                                      "c6",
                                      "d4",
                                      "d5",
                                      "Nc3",
                                      "dxe4",
                                      "Nxe4",
                                      "Bf5",
                                    ],
                                    path: [8, 11],
                                    children: {
                                      Bd3: {
                                        san: "Bd3",
                                        evaluation: "good",
                                        arrows: [["d3", "e4", "#4CA76D"]],
                                        circles: [],
                                        blockEntrainement: false,
                                        history: [
                                          "e4",
                                          "c6",
                                          "d4",
                                          "d5",
                                          "Nc3",
                                          "dxe4",
                                          "Nxe4",
                                          "Bf5",
                                          "Bd3",
                                        ],
                                        path: [9],
                                        children: {
                                          Qxd4: {
                                            san: "Qxd4",
                                            evaluation: "good",
                                            arrows: [],
                                            circles: [],
                                            blockEntrainement: false,
                                            history: [
                                              "e4",
                                              "c6",
                                              "d4",
                                              "d5",
                                              "Nc3",
                                              "dxe4",
                                              "Nxe4",
                                              "Bf5",
                                              "Bd3",
                                              "Qxd4",
                                            ],
                                            path: [10],
                                            children: {},
                                            fen: "rn2kbnr/pp2pppp/2p5/5b2/3qN3/3B4/PPP2PPP/R1BQK1NR w KQkq - 0 6",
                                          },
                                        },
                                        fen: "rn1qkbnr/pp2pppp/2p5/5b2/3PN3/3B4/PPP2PPP/R1BQK1NR b KQkq - 2 5",
                                      },
                                      Ng3: {
                                        san: "Ng3",
                                        evaluation: "good",
                                        arrows: [["g3", "f5", "#C72A2A"]],
                                        circles: [],
                                        blockEntrainement: false,
                                        history: [
                                          "e4",
                                          "c6",
                                          "d4",
                                          "d5",
                                          "Nc3",
                                          "dxe4",
                                          "Nxe4",
                                          "Bf5",
                                          "Ng3",
                                        ],
                                        path: [12],
                                        children: {
                                          Bg6: {
                                            san: "Bg6",
                                            evaluation: "good",
                                            arrows: [],
                                            circles: [],
                                            blockEntrainement: false,
                                            history: [
                                              "e4",
                                              "c6",
                                              "d4",
                                              "d5",
                                              "Nc3",
                                              "dxe4",
                                              "Nxe4",
                                              "Bf5",
                                              "Ng3",
                                              "Bg6",
                                            ],
                                            path: [13, 20],
                                            children: {
                                              Nf3: {
                                                san: "Nf3",
                                                evaluation: "good",
                                                arrows: [],
                                                circles: [],
                                                blockEntrainement: false,
                                                history: [
                                                  "e4",
                                                  "c6",
                                                  "d4",
                                                  "d5",
                                                  "Nc3",
                                                  "dxe4",
                                                  "Nxe4",
                                                  "Bf5",
                                                  "Ng3",
                                                  "Bg6",
                                                  "Nf3",
                                                ],
                                                path: [14],
                                                children: {
                                                  Nf6: {
                                                    san: "Nf6",
                                                    evaluation: "good",
                                                    arrows: [],
                                                    circles: [],
                                                    blockEntrainement: false,
                                                    history: [
                                                      "e4",
                                                      "c6",
                                                      "d4",
                                                      "d5",
                                                      "Nc3",
                                                      "dxe4",
                                                      "Nxe4",
                                                      "Bf5",
                                                      "Ng3",
                                                      "Bg6",
                                                      "Nf3",
                                                      "Nf6",
                                                    ],
                                                    path: [15],
                                                    children: {
                                                      Be2: {
                                                        san: "Be2",
                                                        evaluation: "good",
                                                        arrows: [],
                                                        circles: [],
                                                        blockEntrainement:
                                                          false,
                                                        history: [
                                                          "e4",
                                                          "c6",
                                                          "d4",
                                                          "d5",
                                                          "Nc3",
                                                          "dxe4",
                                                          "Nxe4",
                                                          "Bf5",
                                                          "Ng3",
                                                          "Bg6",
                                                          "Nf3",
                                                          "Nf6",
                                                          "Be2",
                                                        ],
                                                        path: [16],
                                                        children: {
                                                          e6: {
                                                            san: "e6",
                                                            evaluation: "good",
                                                            arrows: [],
                                                            circles: [],
                                                            blockEntrainement:
                                                              false,
                                                            history: [
                                                              "e4",
                                                              "c6",
                                                              "d4",
                                                              "d5",
                                                              "Nc3",
                                                              "dxe4",
                                                              "Nxe4",
                                                              "Bf5",
                                                              "Ng3",
                                                              "Bg6",
                                                              "Nf3",
                                                              "Nf6",
                                                              "Be2",
                                                              "e6",
                                                            ],
                                                            path: [17],
                                                            children: {
                                                              "O-O": {
                                                                san: "O-O",
                                                                evaluation:
                                                                  "good",
                                                                arrows: [],
                                                                circles: [],
                                                                blockEntrainement:
                                                                  false,
                                                                history: [
                                                                  "e4",
                                                                  "c6",
                                                                  "d4",
                                                                  "d5",
                                                                  "Nc3",
                                                                  "dxe4",
                                                                  "Nxe4",
                                                                  "Bf5",
                                                                  "Ng3",
                                                                  "Bg6",
                                                                  "Nf3",
                                                                  "Nf6",
                                                                  "Be2",
                                                                  "e6",
                                                                  "O-O",
                                                                ],
                                                                path: [18],
                                                                children: {
                                                                  Nbd7: {
                                                                    san: "Nbd7",
                                                                    evaluation:
                                                                      "good",

                                                                    arrows: [
                                                                      [
                                                                        "g6",
                                                                        "c2",
                                                                        "#4CA76D",
                                                                      ],
                                                                    ],
                                                                    circles: [
                                                                      [
                                                                        "e6",
                                                                        "#4CA76D",
                                                                      ],
                                                                      [
                                                                        "c6",
                                                                        "#4CA76D",
                                                                      ],
                                                                    ],
                                                                    blockEntrainement:
                                                                      false,
                                                                    history: [
                                                                      "e4",
                                                                      "c6",
                                                                      "d4",
                                                                      "d5",
                                                                      "Nc3",
                                                                      "dxe4",
                                                                      "Nxe4",
                                                                      "Bf5",
                                                                      "Ng3",
                                                                      "Bg6",
                                                                      "Nf3",
                                                                      "Nf6",
                                                                      "Be2",
                                                                      "e6",
                                                                      "O-O",
                                                                      "Nbd7",
                                                                    ],
                                                                    path: [19],
                                                                    children:
                                                                      {},
                                                                    fen: "r2qkb1r/pp1n1ppp/2p1pnb1/8/3P4/5NN1/PPP1BPPP/R1BQ1RK1 w kq - 2 9",
                                                                  },
                                                                },
                                                                fen: "rn1qkb1r/pp3ppp/2p1pnb1/8/3P4/5NN1/PPP1BPPP/R1BQ1RK1 b kq - 1 8",
                                                              },
                                                            },
                                                            fen: "rn1qkb1r/pp3ppp/2p1pnb1/8/3P4/5NN1/PPP1BPPP/R1BQK2R w KQkq - 0 8",
                                                          },
                                                        },
                                                        fen: "rn1qkb1r/pp2pppp/2p2nb1/8/3P4/5NN1/PPP1BPPP/R1BQK2R b KQkq - 6 7",
                                                      },
                                                    },
                                                    fen: "rn1qkb1r/pp2pppp/2p2nb1/8/3P4/5NN1/PPP2PPP/R1BQKB1R w KQkq - 5 7",
                                                  },
                                                },
                                                fen: "rn1qkbnr/pp2pppp/2p3b1/8/3P4/5NN1/PPP2PPP/R1BQKB1R b KQkq - 4 6",
                                              },
                                              h4: {
                                                san: "h4",
                                                evaluation: "good",
                                                arrows: [
                                                  ["h4", "h5", "#D6C34A"],
                                                ],
                                                circles: [],
                                                blockEntrainement: false,
                                                history: [
                                                  "e4",
                                                  "c6",
                                                  "d4",
                                                  "d5",
                                                  "Nc3",
                                                  "dxe4",
                                                  "Nxe4",
                                                  "Bf5",
                                                  "Ng3",
                                                  "Bg6",
                                                  "h4",
                                                ],
                                                path: [21],
                                                children: {
                                                  h6: {
                                                    san: "h6",
                                                    evaluation: "good",
                                                    arrows: [],
                                                    circles: [],
                                                    blockEntrainement: false,
                                                    history: [
                                                      "e4",
                                                      "c6",
                                                      "d4",
                                                      "d5",
                                                      "Nc3",
                                                      "dxe4",
                                                      "Nxe4",
                                                      "Bf5",
                                                      "Ng3",
                                                      "Bg6",
                                                      "h4",
                                                      "h6",
                                                    ],
                                                    path: [22],
                                                    children: {
                                                      h5: {
                                                        san: "h5",
                                                        evaluation: "good",
                                                        arrows: [],
                                                        circles: [],
                                                        blockEntrainement:
                                                          false,
                                                        history: [
                                                          "e4",
                                                          "c6",
                                                          "d4",
                                                          "d5",
                                                          "Nc3",
                                                          "dxe4",
                                                          "Nxe4",
                                                          "Bf5",
                                                          "Ng3",
                                                          "Bg6",
                                                          "h4",
                                                          "h6",
                                                          "h5",
                                                        ],
                                                        path: [23],
                                                        children: {
                                                          Bh7: {
                                                            san: "Bh7",
                                                            evaluation: "good",
                                                            arrows: [],
                                                            circles: [],
                                                            blockEntrainement:
                                                              false,
                                                            history: [
                                                              "e4",
                                                              "c6",
                                                              "d4",
                                                              "d5",
                                                              "Nc3",
                                                              "dxe4",
                                                              "Nxe4",
                                                              "Bf5",
                                                              "Ng3",
                                                              "Bg6",
                                                              "h4",
                                                              "h6",
                                                              "h5",
                                                              "Bh7",
                                                            ],
                                                            path: [24],
                                                            children: {},
                                                            fen: "rn1qkbnr/pp2pppb/2p4p/7P/3P4/6N1/PPP2PP1/R1BQKBNR w KQkq - 1 8",
                                                          },
                                                        },
                                                        fen: "rn1qkbnr/pp2ppp1/2p3bp/7P/3P4/6N1/PPP2PP1/R1BQKBNR b KQkq - 0 7",
                                                      },
                                                    },
                                                    fen: "rn1qkbnr/pp2ppp1/2p3bp/8/3P3P/6N1/PPP2PP1/R1BQKBNR w KQkq - 0 7",
                                                  },
                                                },
                                                fen: "rn1qkbnr/pp2pppp/2p3b1/8/3P3P/6N1/PPP2PP1/R1BQKBNR b KQkq - 0 6",
                                              },
                                            },
                                            fen: "rn1qkbnr/pp2pppp/2p3b1/8/3P4/6N1/PPP2PPP/R1BQKBNR w KQkq - 3 6",
                                          },
                                        },
                                        fen: "rn1qkbnr/pp2pppp/2p5/5b2/3P4/6N1/PPP2PPP/R1BQKBNR b KQkq - 2 5",
                                      },
                                    },
                                    fen: "rn1qkbnr/pp2pppp/2p5/5b2/3PN3/8/PPP2PPP/R1BQKBNR w KQkq - 1 5",
                                  },
                                },
                                fen: "rnbqkbnr/pp2pppp/2p5/8/3PN3/8/PPP2PPP/R1BQKBNR b KQkq - 0 4",
                              },
                            },
                            fen: "rnbqkbnr/pp2pppp/2p5/8/3Pp3/2N5/PPP2PPP/R1BQKBNR w KQkq - 0 4",
                          },
                        },
                        fen: "rnbqkbnr/pp2pppp/2p5/3p4/3PP3/2N5/PPP2PPP/R1BQKBNR b KQkq - 1 3",
                      },
                      Bd3: {
                        san: "Bd3",
                        evaluation: "mistake",
                        arrows: [["d3", "e4", "#4CA76D"]],
                        circles: [],
                        blockEntrainement: false,
                        history: ["e4", "c6", "d4", "d5", "Bd3"],
                        path: [60],
                        children: {
                          dxe4: {
                            san: "dxe4",
                            evaluation: "good",
                            arrows: [],
                            circles: [],
                            blockEntrainement: false,
                            history: ["e4", "c6", "d4", "d5", "Bd3", "dxe4"],
                            path: [61],
                            children: {
                              Bxe4: {
                                san: "Bxe4",
                                evaluation: "good",
                                arrows: [],
                                circles: [],
                                blockEntrainement: false,
                                history: [
                                  "e4",
                                  "c6",
                                  "d4",
                                  "d5",
                                  "Bd3",
                                  "dxe4",
                                  "Bxe4",
                                ],
                                path: [62],
                                children: {
                                  Nf6: {
                                    san: "Nf6",
                                    evaluation: "good",
                                    arrows: [["f6", "e4", "#C72A2A"]],
                                    circles: [],
                                    blockEntrainement: false,
                                    history: [
                                      "e4",
                                      "c6",
                                      "d4",
                                      "d5",
                                      "Bd3",
                                      "dxe4",
                                      "Bxe4",
                                      "Nf6",
                                    ],
                                    path: [63],
                                    children: {},
                                    fen: "rnbqkb1r/pp2pppp/2p2n2/8/3PB3/8/PPP2PPP/RNBQK1NR w KQkq - 1 5",
                                  },
                                },
                                fen: "rnbqkbnr/pp2pppp/2p5/8/3PB3/8/PPP2PPP/RNBQK1NR b KQkq - 0 4",
                              },
                            },
                            fen: "rnbqkbnr/pp2pppp/2p5/8/3Pp3/3B4/PPP2PPP/RNBQK1NR w KQkq - 0 4",
                          },
                        },
                        fen: "rnbqkbnr/pp2pppp/2p5/3p4/3PP3/3B4/PPP2PPP/RNBQK1NR b KQkq - 1 3",
                      },
                      Qf3: {
                        san: "Qf3",
                        evaluation: "mistake",
                        arrows: [["f3", "e4", "#4CA76D"]],
                        circles: [],
                        blockEntrainement: false,
                        history: ["e4", "c6", "d4", "d5", "Qf3"],
                        path: [65],
                        children: {
                          dxe4: {
                            san: "dxe4",
                            evaluation: "good",
                            arrows: [],
                            circles: [],
                            blockEntrainement: false,
                            history: ["e4", "c6", "d4", "d5", "Qf3", "dxe4"],
                            path: [66],
                            children: {
                              Qxe4: {
                                san: "Qxe4",
                                evaluation: "good",
                                arrows: [],
                                circles: [],
                                blockEntrainement: false,
                                history: [
                                  "e4",
                                  "c6",
                                  "d4",
                                  "d5",
                                  "Qf3",
                                  "dxe4",
                                  "Qxe4",
                                ],
                                path: [67],
                                children: {
                                  Nf6: {
                                    san: "Nf6",
                                    evaluation: "good",
                                    arrows: [["f6", "e4", "#C72A2A"]],
                                    circles: [],
                                    blockEntrainement: false,
                                    history: [
                                      "e4",
                                      "c6",
                                      "d4",
                                      "d5",
                                      "Qf3",
                                      "dxe4",
                                      "Qxe4",
                                      "Nf6",
                                    ],
                                    path: [68],
                                    children: {},
                                    fen: "rnbqkb1r/pp2pppp/2p2n2/8/3PQ3/8/PPP2PPP/RNB1KBNR w KQkq - 1 5",
                                  },
                                },
                                fen: "rnbqkbnr/pp2pppp/2p5/8/3PQ3/8/PPP2PPP/RNB1KBNR b KQkq - 0 4",
                              },
                            },
                            fen: "rnbqkbnr/pp2pppp/2p5/8/3Pp3/5Q2/PPP2PPP/RNB1KBNR w KQkq - 0 4",
                          },
                        },
                        fen: "rnbqkbnr/pp2pppp/2p5/3p4/3PP3/5Q2/PPP2PPP/RNB1KBNR b KQkq - 1 3",
                      },
                      exd5: {
                        san: "exd5",
                        evaluation: "good",
                        arrows: [],
                        circles: [],
                        blockEntrainement: false,
                        history: ["e4", "c6", "d4", "d5", "exd5"],
                        path: [45],
                        children: {
                          cxd5: {
                            san: "cxd5",
                            evaluation: "good",
                            arrows: [],
                            circles: [],
                            blockEntrainement: false,
                            history: ["e4", "c6", "d4", "d5", "exd5", "cxd5"],
                            path: [46, 49, 54],
                            children: {
                              Bd3: {
                                san: "Bd3",
                                evaluation: "good",
                                arrows: [],
                                circles: [],
                                blockEntrainement: false,
                                history: [
                                  "e4",
                                  "c6",
                                  "d4",
                                  "d5",
                                  "exd5",
                                  "cxd5",
                                  "Bd3",
                                ],
                                path: [47],
                                children: {
                                  Nc6: {
                                    san: "Nc6",
                                    evaluation: "good",
                                    arrows: [],
                                    circles: [],
                                    blockEntrainement: false,
                                    history: [
                                      "e4",
                                      "c6",
                                      "d4",
                                      "d5",
                                      "exd5",
                                      "cxd5",
                                      "Bd3",
                                      "Nc6",
                                    ],
                                    path: [48],
                                    children: {},
                                    fen: "r1bqkbnr/pp2pppp/2n5/3p4/3P4/3B4/PPP2PPP/RNBQK1NR w KQkq - 2 5",
                                  },
                                },
                                fen: "rnbqkbnr/pp2pppp/8/3p4/3P4/3B4/PPP2PPP/RNBQK1NR b KQkq - 1 4",
                              },
                              c4: {
                                san: "c4",
                                evaluation: "good",
                                arrows: [["c4", "d5", "#C72A2A"]],
                                circles: [],
                                blockEntrainement: false,
                                history: [
                                  "e4",
                                  "c6",
                                  "d4",
                                  "d5",
                                  "exd5",
                                  "cxd5",
                                  "c4",
                                ],
                                path: [50],
                                children: {
                                  Nf6: {
                                    san: "Nf6",
                                    evaluation: "good",
                                    arrows: [],
                                    circles: [],
                                    blockEntrainement: false,
                                    history: [
                                      "e4",
                                      "c6",
                                      "d4",
                                      "d5",
                                      "exd5",
                                      "cxd5",
                                      "c4",
                                      "Nf6",
                                    ],
                                    path: [51],
                                    children: {
                                      Nc3: {
                                        san: "Nc3",
                                        evaluation: "good",
                                        arrows: [
                                          ["c3", "d5", "#C72A2A"],
                                          ["c4", "d5", "#C72A2A"],
                                        ],
                                        circles: [],
                                        blockEntrainement: false,
                                        history: [
                                          "e4",
                                          "c6",
                                          "d4",
                                          "d5",
                                          "exd5",
                                          "cxd5",
                                          "c4",
                                          "Nf6",
                                          "Nc3",
                                        ],
                                        path: [52],
                                        children: {
                                          e6: {
                                            san: "e6",
                                            evaluation: "good",
                                            arrows: [
                                              ["f8", "b4", "#D6C34A"],
                                              ["e8", "g8", "#D6C34A"],
                                            ],
                                            circles: [],
                                            blockEntrainement: false,
                                            history: [
                                              "e4",
                                              "c6",
                                              "d4",
                                              "d5",
                                              "exd5",
                                              "cxd5",
                                              "c4",
                                              "Nf6",
                                              "Nc3",
                                              "e6",
                                            ],
                                            path: [53],
                                            children: {},
                                            fen: "rnbqkb1r/pp3ppp/4pn2/3p4/2PP4/2N5/PP3PPP/R1BQKBNR w KQkq - 0 6",
                                          },
                                        },
                                        fen: "rnbqkb1r/pp2pppp/5n2/3p4/2PP4/2N5/PP3PPP/R1BQKBNR b KQkq - 2 5",
                                      },
                                    },
                                    fen: "rnbqkb1r/pp2pppp/5n2/3p4/2PP4/8/PP3PPP/RNBQKBNR w KQkq - 1 5",
                                  },
                                },
                                fen: "rnbqkbnr/pp2pppp/8/3p4/2PP4/8/PP3PPP/RNBQKBNR b KQkq - 0 4",
                              },
                              "Bb5+": {
                                san: "Bb5+",
                                evaluation: "mistake",
                                arrows: [],
                                circles: [],
                                blockEntrainement: false,
                                history: [
                                  "e4",
                                  "c6",
                                  "d4",
                                  "d5",
                                  "exd5",
                                  "cxd5",
                                  "Bb5+",
                                ],
                                path: [55],
                                children: {
                                  Bd7: {
                                    san: "Bd7",
                                    evaluation: "good",
                                    arrows: [],
                                    circles: [],
                                    blockEntrainement: false,
                                    history: [
                                      "e4",
                                      "c6",
                                      "d4",
                                      "d5",
                                      "exd5",
                                      "cxd5",
                                      "Bb5+",
                                      "Bd7",
                                    ],
                                    path: [56],
                                    children: {
                                      "Bxd7+": {
                                        san: "Bxd7+",
                                        evaluation: "good",
                                        arrows: [],
                                        circles: [],
                                        blockEntrainement: false,
                                        history: [
                                          "e4",
                                          "c6",
                                          "d4",
                                          "d5",
                                          "exd5",
                                          "cxd5",
                                          "Bb5+",
                                          "Bd7",
                                          "Bxd7+",
                                        ],
                                        path: [57],
                                        children: {
                                          Qxd7: {
                                            san: "Qxd7",
                                            evaluation: "good",
                                            arrows: [["e7", "e6", "#D6C34A"]],
                                            circles: [],
                                            blockEntrainement: false,
                                            history: [
                                              "e4",
                                              "c6",
                                              "d4",
                                              "d5",
                                              "exd5",
                                              "cxd5",
                                              "Bb5+",
                                              "Bd7",
                                              "Bxd7+",
                                              "Qxd7",
                                            ],
                                            path: [58],
                                            children: {},
                                            fen: "rn2kbnr/pp1qpppp/8/3p4/3P4/8/PPP2PPP/RNBQK1NR w KQkq - 0 6",
                                          },
                                        },
                                        fen: "rn1qkbnr/pp1Bpppp/8/3p4/3P4/8/PPP2PPP/RNBQK1NR b KQkq - 0 5",
                                      },
                                    },
                                    fen: "rn1qkbnr/pp1bpppp/8/1B1p4/3P4/8/PPP2PPP/RNBQK1NR w KQkq - 2 5",
                                  },
                                },
                                fen: "rnbqkbnr/pp2pppp/8/1B1p4/3P4/8/PPP2PPP/RNBQK1NR b KQkq - 1 4",
                              },
                            },
                            fen: "rnbqkbnr/pp2pppp/8/3p4/3P4/8/PPP2PPP/RNBQKBNR w KQkq - 0 4",
                          },
                        },
                        fen: "rnbqkbnr/pp2pppp/2p5/3P4/3P4/8/PPP2PPP/RNBQKBNR b KQkq - 0 3",
                      },
                      e5: {
                        san: "e5",
                        evaluation: "good",
                        arrows: [
                          ["e5", "f6", "#C72A2A"],
                          ["g8", "f6", "#4CA76D"],
                        ],
                        circles: [],
                        blockEntrainement: false,
                        history: ["e4", "c6", "d4", "d5", "e5"],
                        path: [26],
                        children: {
                          Bf5: {
                            san: "Bf5",
                            evaluation: "good",
                            arrows: [],
                            circles: [],
                            blockEntrainement: false,
                            history: ["e4", "c6", "d4", "d5", "e5", "Bf5"],
                            path: [27, 32],
                            children: {
                              Nf3: {
                                san: "Nf3",
                                evaluation: "good",
                                arrows: [],
                                circles: [],
                                blockEntrainement: false,
                                history: [
                                  "e4",
                                  "c6",
                                  "d4",
                                  "d5",
                                  "e5",
                                  "Bf5",
                                  "Nf3",
                                ],
                                path: [28],
                                children: {
                                  e6: {
                                    san: "e6",
                                    evaluation: "good",
                                    arrows: [],
                                    circles: [],
                                    blockEntrainement: false,
                                    history: [
                                      "e4",
                                      "c6",
                                      "d4",
                                      "d5",
                                      "e5",
                                      "Bf5",
                                      "Nf3",
                                      "e6",
                                    ],
                                    path: [29],
                                    children: {
                                      Bd3: {
                                        san: "Bd3",
                                        evaluation: "good",
                                        arrows: [],
                                        circles: [],
                                        blockEntrainement: false,
                                        history: [
                                          "e4",
                                          "c6",
                                          "d4",
                                          "d5",
                                          "e5",
                                          "Bf5",
                                          "Nf3",
                                          "e6",
                                          "Bd3",
                                        ],
                                        path: [30],
                                        children: {
                                          Bxd3: {
                                            san: "Bxd3",
                                            evaluation: "good",
                                            arrows: [],
                                            circles: [],
                                            blockEntrainement: false,
                                            history: [
                                              "e4",
                                              "c6",
                                              "d4",
                                              "d5",
                                              "e5",
                                              "Bf5",
                                              "Nf3",
                                              "e6",
                                              "Bd3",
                                              "Bxd3",
                                            ],
                                            path: [31],
                                            children: {},
                                            fen: "rn1qkbnr/pp3ppp/2p1p3/3pP3/3P4/3b1N2/PPP2PPP/RNBQK2R w KQkq - 0 6",
                                          },
                                        },
                                        fen: "rn1qkbnr/pp3ppp/2p1p3/3pPb2/3P4/3B1N2/PPP2PPP/RNBQK2R b KQkq - 1 5",
                                      },
                                    },
                                    fen: "rn1qkbnr/pp3ppp/2p1p3/3pPb2/3P4/5N2/PPP2PPP/RNBQKB1R w KQkq - 0 5",
                                  },
                                },
                                fen: "rn1qkbnr/pp2pppp/2p5/3pPb2/3P4/5N2/PPP2PPP/RNBQKB1R b KQkq - 2 4",
                              },
                              h4: {
                                san: "h4",
                                evaluation: "good",
                                arrows: [],
                                circles: [],
                                blockEntrainement: false,
                                history: [
                                  "e4",
                                  "c6",
                                  "d4",
                                  "d5",
                                  "e5",
                                  "Bf5",
                                  "h4",
                                ],
                                path: [33, 35],
                                children: {
                                  e6: {
                                    san: "e6",
                                    evaluation: "error",
                                    arrows: [],
                                    circles: [],
                                    blockEntrainement: true,
                                    history: [
                                      "e4",
                                      "c6",
                                      "d4",
                                      "d5",
                                      "e5",
                                      "Bf5",
                                      "h4",
                                      "e6",
                                    ],
                                    path: [36],
                                    children: {
                                      g4: {
                                        san: "g4",
                                        evaluation: "good",
                                        arrows: [],
                                        circles: [],
                                        blockEntrainement: false,
                                        history: [
                                          "e4",
                                          "c6",
                                          "d4",
                                          "d5",
                                          "e5",
                                          "Bf5",
                                          "h4",
                                          "e6",
                                          "g4",
                                        ],
                                        path: [37],
                                        children: {
                                          Bg6: {
                                            san: "Bg6",
                                            evaluation: "good",
                                            arrows: [],
                                            circles: [],
                                            blockEntrainement: false,
                                            history: [
                                              "e4",
                                              "c6",
                                              "d4",
                                              "d5",
                                              "e5",
                                              "Bf5",
                                              "h4",
                                              "e6",
                                              "g4",
                                              "Bg6",
                                            ],
                                            path: [38],
                                            children: {
                                              h5: {
                                                san: "h5",
                                                evaluation: "good",
                                                arrows: [],
                                                circles: [],
                                                blockEntrainement: false,
                                                history: [
                                                  "e4",
                                                  "c6",
                                                  "d4",
                                                  "d5",
                                                  "e5",
                                                  "Bf5",
                                                  "h4",
                                                  "e6",
                                                  "g4",
                                                  "Bg6",
                                                  "h5",
                                                ],
                                                path: [39],
                                                children: {
                                                  Be4: {
                                                    san: "Be4",
                                                    evaluation: "good",
                                                    arrows: [],
                                                    circles: [],
                                                    blockEntrainement: false,
                                                    history: [
                                                      "e4",
                                                      "c6",
                                                      "d4",
                                                      "d5",
                                                      "e5",
                                                      "Bf5",
                                                      "h4",
                                                      "e6",
                                                      "g4",
                                                      "Bg6",
                                                      "h5",
                                                      "Be4",
                                                    ],
                                                    path: [40],
                                                    children: {
                                                      f3: {
                                                        san: "f3",
                                                        evaluation: "good",
                                                        arrows: [],
                                                        circles: [],
                                                        blockEntrainement:
                                                          false,
                                                        history: [
                                                          "e4",
                                                          "c6",
                                                          "d4",
                                                          "d5",
                                                          "e5",
                                                          "Bf5",
                                                          "h4",
                                                          "e6",
                                                          "g4",
                                                          "Bg6",
                                                          "h5",
                                                          "Be4",
                                                          "f3",
                                                        ],
                                                        path: [41],
                                                        children: {
                                                          Bxf3: {
                                                            san: "Bxf3",
                                                            evaluation: "good",
                                                            arrows: [],
                                                            circles: [],
                                                            blockEntrainement:
                                                              false,
                                                            history: [
                                                              "e4",
                                                              "c6",
                                                              "d4",
                                                              "d5",
                                                              "e5",
                                                              "Bf5",
                                                              "h4",
                                                              "e6",
                                                              "g4",
                                                              "Bg6",
                                                              "h5",
                                                              "Be4",
                                                              "f3",
                                                              "Bxf3",
                                                            ],
                                                            path: [42],
                                                            children: {
                                                              Qxf3: {
                                                                san: "Qxf3",
                                                                evaluation:
                                                                  "good",
                                                                arrows: [],
                                                                circles: [],
                                                                blockEntrainement:
                                                                  false,
                                                                history: [
                                                                  "e4",
                                                                  "c6",
                                                                  "d4",
                                                                  "d5",
                                                                  "e5",
                                                                  "Bf5",
                                                                  "h4",
                                                                  "e6",
                                                                  "g4",
                                                                  "Bg6",
                                                                  "h5",
                                                                  "Be4",
                                                                  "f3",
                                                                  "Bxf3",
                                                                  "Qxf3",
                                                                ],
                                                                path: [43],
                                                                children: {},
                                                                fen: "rn1qkbnr/pp3ppp/2p1p3/3pP2P/3P2P1/5Q2/PPP5/RNB1KBNR b KQkq - 0 8",
                                                              },
                                                            },
                                                            fen: "rn1qkbnr/pp3ppp/2p1p3/3pP2P/3P2P1/5b2/PPP5/RNBQKBNR w KQkq - 0 8",
                                                          },
                                                        },
                                                        fen: "rn1qkbnr/pp3ppp/2p1p3/3pP2P/3Pb1P1/5P2/PPP5/RNBQKBNR b KQkq - 0 7",
                                                      },
                                                    },
                                                    fen: "rn1qkbnr/pp3ppp/2p1p3/3pP2P/3Pb1P1/8/PPP2P2/RNBQKBNR w KQkq - 1 7",
                                                  },
                                                },
                                                fen: "rn1qkbnr/pp3ppp/2p1p1b1/3pP2P/3P2P1/8/PPP2P2/RNBQKBNR b KQkq - 0 6",
                                              },
                                            },
                                            fen: "rn1qkbnr/pp3ppp/2p1p1b1/3pP3/3P2PP/8/PPP2P2/RNBQKBNR w KQkq - 1 6",
                                          },
                                        },
                                        fen: "rn1qkbnr/pp3ppp/2p1p3/3pPb2/3P2PP/8/PPP2P2/RNBQKBNR b KQkq - 0 5",
                                      },
                                    },
                                    fen: "rn1qkbnr/pp3ppp/2p1p3/3pPb2/3P3P/8/PPP2PP1/RNBQKBNR w KQkq - 0 5",
                                  },
                                  h6: {
                                    san: "h6",
                                    evaluation: "good",
                                    arrows: [],
                                    circles: [],
                                    blockEntrainement: false,

                                    history: [
                                      "e4",
                                      "c6",
                                      "d4",
                                      "d5",
                                      "e5",
                                      "Bf5",
                                      "h4",
                                      "h6",
                                    ],
                                    path: [34],
                                    children: {},
                                    fen: "rn1qkbnr/pp2ppp1/2p4p/3pPb2/3P3P/8/PPP2PP1/RNBQKBNR w KQkq - 0 5",
                                  },
                                },
                                fen: "rn1qkbnr/pp2pppp/2p5/3pPb2/3P3P/8/PPP2PP1/RNBQKBNR b KQkq - 0 4",
                              },
                            },
                            fen: "rn1qkbnr/pp2pppp/2p5/3pPb2/3P4/8/PPP2PPP/RNBQKBNR w KQkq - 1 4",
                          },
                        },
                        fen: "rnbqkbnr/pp2pppp/2p5/3pP3/3P4/8/PPP2PPP/RNBQKBNR b KQkq - 0 3",
                      },
                    },
                    fen: "rnbqkbnr/pp2pppp/2p5/3p4/3PP3/8/PPP2PPP/RNBQKBNR w KQkq - 0 3",
                  },
                },
                fen: "rnbqkbnr/pp1ppppp/2p5/8/3PP3/8/PPP2PPP/RNBQKBNR b KQkq - 0 2",
              },
            },
            fen: "rnbqkbnr/pp1ppppp/2p5/8/4P3/8/PPPP1PPP/RNBQKBNR w KQkq - 0 2",
          },
        },
        fen: "rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR b KQkq - 0 1",
      },
    },
    fen: "rnbqkbnr/pppppppp/8/8/8/8/PPPPPPPP/RNBQKBNR w KQkq - 0 1",
  },
};
