import { FaTrash } from "react-icons/fa";
import icons from "../../../../assets/svg/icons";
import useCreateOpeningStore from "../../../../store/useCreateOpeningStore";
import { OpeningMove, TreeNode } from "../../../../types";
import NodeWithNoOrManyChildren from "../../../TreeOpening/CustomNode/NodeCreation/NodeWithNoOrManyChildren/NodeWithNoOrManyChildren";
import NodeWithSingleChild from "../../../TreeOpening/CustomNode/NodeCreation/NodeWithSingleChild/NodeWithSingleChild";
import "./LegendeCreateTree.scss";

export default function LegendeCreateTree() {
  const openingMoveNoPathExample: OpeningMove = {
    evaluation: "good",
    arrows: [],
    circles: [],
    children: {},
    history: ["e4", "e5", "Nf3", "Nc6"],
    san: "Nf3",
    path: [],
    blockEntrainement: false,
    fen: "rnbqkb1r/pppppppp/8/8/4N3/8/PPPPPPPP/R1BQKB1R w KQkq - 0 1",
  };

  const openingMoveNoPath: OpeningMove = {
    evaluation: "good",
    arrows: [],
    circles: [],
    children: { openingMoveNoPathExample },
    history: ["e4", "e5", "Nf3", "Nc6"],
    san: "Nf3",
    path: [],
    blockEntrainement: false,
    fen: "rnbqkb1r/pppppppp/8/8/4N3/8/PPPPPPPP/R1BQKB1R w KQkq - 0 1",
  };

  // Exemple de nœud d'arbre
  const treeNodeNonclickNoPath: TreeNode = {
    name: "e4",
    move: openingMoveNoPath,
    children: [], // Pas d'enfants pour cet exemple
    isCurrentNode: false,
    isAncestorNode: true,
  };

  const treeNodeNonclick: TreeNode = {
    name: "e4",
    move: openingMoveNoPathExample,
    children: [], // Pas d'enfants pour cet exemple
    isCurrentNode: false,
    isAncestorNode: true,
  };

  const { pathIndex, decrementCurrentPageIndex } = useCreateOpeningStore();

  const handlePastPath = () => {
    decrementCurrentPageIndex();
  };

  return (
    <div className="legendeCreateTree">
      <div className="legendeCreateTree_explain">
        Chaque coup que vous créez est associé à un numéro de page. Pour ajouter
        un numéro de page, cliquez sur un nœud cliquable qui est connecté à un
        autre nœud. Chaque clic incrémente automatiquement le numéro de la page
        par rapport à la page précédente, ce qui servira à guider vos lecteurs.
      </div>
      <div className="legendeCreateTree_svg">
        <div>
          Coup non relie.
          <svg>
            <g transform="translate(0, 15) ">
              <line stroke="black" x1="0" y1="0" x2="200" y2="0" />
            </g>
          </svg>
        </div>
        <div>
          Coup relié.
          <svg>
            <g transform="translate(0, 15)">
              <line className="link-ancestor" x1="0" y1="0" x2="200" y2="0" />
            </g>
          </svg>
        </div>
        <div>
          Interdit de d'utiliser cette branche pour le mode entrainement
          <div>{icons.stop}</div>
        </div>

        <div className="legendeCreateTree_NoClickWithPath">
          Noeud non cliquable avec page{" "}
          <svg>
            <g transform="translate(15, 15) scale(0.7)">
              <NodeWithSingleChild
                nodeDatum={treeNodeNonclick} // Utilisation de l'exemple de nœud
                handleMouseLeave={() => {}}
                handleMouseEnter={() => {}}
              />
            </g>
          </svg>
        </div>
        <div>
          Noeud non cliquable sans page.
          <svg>
            <g transform="translate(15, 15) scale(0.8)">
              <NodeWithSingleChild
                nodeDatum={treeNodeNonclickNoPath} // Utilisation de l'exemple de nœud
                handleMouseLeave={() => {}}
                handleMouseEnter={() => {}}
              />
            </g>
          </svg>
        </div>
        <div>
          Noeud cliquable.
          <svg>
            <g transform="translate(15, 15) scale(0.7)">
              <NodeWithNoOrManyChildren
                nodeDatum={treeNodeNonclick} // Utilisation de l'exemple de nœud
                handleMouseLeave={() => {}}
                handleMouseEnter={() => {}}
              />
            </g>
          </svg>
        </div>

        <div>Page actuel: {pathIndex}</div>

        <button
          onClick={handlePastPath}
          className="legendeCreateTree_delete"
          title="Supprimer"
        >
          Delete last page <FaTrash />
        </button>
      </div>
    </div>
  );
}
