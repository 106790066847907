// GoogleSignInButton.jsx

import { GoogleLogin, GoogleOAuthProvider } from '@react-oauth/google';
import axios from 'axios';
import React from 'react';
import { useUserStore } from '../../../../store/useUserStore';
import './GoogleSignInButton.scss';
const API_URL = process.env.REACT_APP_API_URL || '';
const CLIENT_ID = process.env.REACT_APP_CLIENT_ID || '';

const GoogleSignInButton: React.FC = () => {
  const { setIsLogged, checkLoginStatus } = useUserStore();
  return (
    <div className="googleSignInButton">
      <GoogleOAuthProvider clientId={CLIENT_ID}>
        <GoogleLogin
          onSuccess={async (credentialResponse) => {
            if (credentialResponse.credential) {
              try {
                await axios.post(
                  `${API_URL}/api/google-login`,
                  { token: credentialResponse.credential },
                  { withCredentials: true }
                );
                // Update application state
                await checkLoginStatus();
              } catch (error) {
                console.error('Login Failed:', error);
              }
            } else {
              console.error('No credential returned');
            }
          }}
          onError={() => {
            console.error('Login Failed');
          }}
          size="large"          // Ensures the button is large
          text="signin_with"    // Sets the button text to "Sign in with Google"
          auto_select={false}   // Prevents auto-selecting the account
          theme="outline"       // Options: 'outline', 'filled_blue', 'filled_black'
          shape="rectangular"   // Options: 'rectangular', 'pill', 'circle', 'square'
          width="300"           // Sets a fixed width for the button
        />
      </GoogleOAuthProvider>
    </div>
  );
};

export default GoogleSignInButton;
