import confetti from "canvas-confetti";

export const fireConfetti = () => {
  // Canon tirant à gauche
  confetti({
    particleCount: 70,
    spread: 70,
    angle: 45, // Canon tirant en diagonale de la gauche vers le haut
    origin: { x: 0.0, y: 1 }, // Partie gauche en bas
    zIndex: 1000, // S'assurer que les confettis sont devant tout le reste
  });

  // Canon tirant à droite
  confetti({
    particleCount: 70,
    spread: 70,
    angle: 135, // Canon tirant en diagonale de la droite vers le haut
    origin: { x: 1, y: 1 }, // Partie droite en bas
    zIndex: 1000, // S'assurer que les confettis sont devant tout le reste
  });
};
