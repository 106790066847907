import { Arrow } from "react-chessboard/dist/chessboard/types";
import { create } from "zustand";
import { createJSONStorage, persist } from "zustand/middleware"; // Import the persist middleware
import { config } from "../config";
import { mockOpeningEmptyData } from "../mock/mockOpeninEmptyData";
import {
  Language,
  OpeningDataHomePage,
  OpeningMove,
  TreeNode,
  TreeOpeningData,
} from "../types";
import { GameChess } from "../utils/GameChess";
import { OpeningManager } from "../utils/OpeningManager";
import TreeManager from "../utils/TreeManager";

interface CreateOpeningState {
  // Pour la gestion de l'ouverture
  openingManager: OpeningManager;
  // Gestion de l'initialisation

  resetStore: () => void;
  setOpeningManager: (newTreeOpeningData: TreeOpeningData) => void;
  addMove: (moveData: OpeningMove) => OpeningMove;
  updateMove: (moveData: OpeningMove) => boolean;
  updateBlockEntrainement: (
    move: OpeningMove,
    blockEntrainement: boolean
  ) => void;
  clearMove: (move: OpeningMove) => OpeningMove;
  deleteMove: (move: OpeningMove) => null | OpeningMove;
  getOpeningTree: () => OpeningMove;
  getOpeningTreeConverted: () => TreeNode | null;

  // Gestion des commentaires
  comments: { [fen: string]: { [lang: string]: string } };
  // Mettre à jour le commentaire
  updateComment: (newComment: string, lang: string) => void;

  // Récupérer un commentaire
  getComment: (fen: string, lang: Language) => string | null;

  //information sur l'ouverture
  infoOpeningData: OpeningDataHomePage;
  updateInfoOpeningData: (infoOpeningData: OpeningDataHomePage) => void;

  // Gestion de la navigation
  currentMove: OpeningMove;
  treeOpeningData: TreeOpeningData;
  loadMove: (moveDetail: OpeningMove) => void;
  setCurrentMove: (move: OpeningMove) => void;

  // Les informations du panel
  colorArrow: string; // La variable de couleur
  setColorArrow: (colorArrow: string) => void; // Fonction pour modifier la couleur

  // Informations sur les éléments du chessboard
  gameChess: GameChess;
  arrows: Arrow[];
  setArrows: (arrows: Arrow[]) => void;
  resetGame: () => void;

  //Gestion de la modal
  pathIndex: number;
  setpath: (index: number) => void;
  selectedNode: TreeNode | null;
  setSelectedNode: (node: TreeNode | null) => void;
  addMultiPath: (nodeStart: TreeNode, nodeFinal: TreeNode) => void;
  decrementCurrentPageIndex: () => void;
  checkAllMovesHavePath: () => boolean;
}

const useCreateOpeningStore = create(
  persist<CreateOpeningState>(
    (set, get) => ({
      openingManager: new OpeningManager(mockOpeningEmptyData),
      gameChess: new GameChess(),
      pathIndex: 1, //commence a 1
      treeOpeningData: mockOpeningEmptyData,

      resetStore: () => {
        set({
          openingManager: new OpeningManager(mockOpeningEmptyData),
          gameChess: new GameChess(),
          pathIndex: 1,
          infoOpeningData: config.emptyInfoOpeningData,
          treeOpeningData: mockOpeningEmptyData,
          currentMove: config.emptyOpeningMove,
          comments: {}, // Réinitialiser les commentaires si nécessaire
        });
      },

      setOpeningManager: (newTreeOpeningData: TreeOpeningData) => {
        set({
          openingManager: new OpeningManager(newTreeOpeningData),
        });
      },

      infoOpeningData: config.emptyInfoOpeningData,

      // Méthode pour mettre à jour infoOpeningData
      updateInfoOpeningData: (updatedData: OpeningDataHomePage) => {
        // Créer une nouvelle version de l'objet avec les données mises à jour
        const newInfoOpeningData = {
          ...updatedData, // Écraser les propriétés avec celles fournies dans updatedData
        };

        set({ infoOpeningData: newInfoOpeningData });
      },

      comments: {}, // Initialiser avec un objet vide pour stocker les commentaires

      getComment: (fen: string, lang: string): string | null => {
        const commentsForFEN = get().comments[fen]; // Récupérer les commentaires pour la position FEN donnée

        if (!commentsForFEN) {
          return null; // Si aucun commentaire n'existe pour cette position FEN
        }

        return commentsForFEN[lang] || null; // Retourner le commentaire dans la langue donnée, ou null si non défini
      },

      updateComment: (newComment: string, lang: string) => {
        let fen = get().gameChess.getBoardPosition(); // Obtenir la position actuelle en FEN

        // Obtenir les commentaires actuels de l'état (store) pour la position FEN actuelle
        const currentComments = get().comments[fen] || {};

        // Mettre à jour le commentaire dans la langue spécifiée
        const updatedComments = {
          ...currentComments,
          [lang]: newComment, // Mettre à jour le commentaire pour la langue spécifiée
        };

        // Stocker les commentaires mis à jour dans l'état global (comments)
        set((state) => ({
          comments: {
            ...state.comments,
            [fen]: updatedComments, // Mettre à jour les commentaires pour la position FEN actuelle
          },
        }));
      },

      // Configuration du panel
      colorArrow: "#FF0000",
      setColorArrow: (colorArrow: string) => set({ colorArrow }),

      // Informations sur l'échiquier
      arrows: [],

      addMove: (moveData: OpeningMove): OpeningMove => {
        const openingManager = get().openingManager;

        const isExistingMove = openingManager.getMoveDetails(moveData.history); //

        if (!isExistingMove) {
          //Si il n'eiste pas allors on l'ajoute
          const result = openingManager.addMove(moveData);

          if (result) {
            get().gameChess.loadFromFEN(moveData.fen);
            set({ currentMove: moveData });

            set({ treeOpeningData: get().openingManager.getTreeOpeningData() });
          }
          return result;
        }

        get().gameChess.loadFromFEN(moveData.fen);
        set({ currentMove: isExistingMove });
        return isExistingMove;
      },

      clearMove: (move: OpeningMove): OpeningMove => {
        // Crée une copie de 'move' en modifiant uniquement les champs nécessaires
        const clearMove: OpeningMove = {
          ...move,
          evaluation: "good", // Assurer que l'évaluation soit l'une des valeurs permises
          arrows: [], // Réinitialiser les flèches
          circles: [], // Réinitialiser les cercles
        };

        // Récupère l'instance de 'openingManager' et tente de mettre à jour le coup
        const openingManager = get().openingManager;
        const isGood = openingManager.updateMove(clearMove);

        // Si la mise à jour est réussie, on met à jour le mouvement courant
        if (isGood) {
          get().setCurrentMove(clearMove);
        }

        return clearMove; // Retourne le mouvement modifié
      },
      updateMove: (moveData: OpeningMove): boolean => {
        const openingManager = get().openingManager;

        const result = openingManager.updateMove(moveData);
        if (result) {
          get().setCurrentMove(moveData);
        }
        return result;
      },

      updateBlockEntrainement: (
        move: OpeningMove,
        blockEntrainement: boolean
      ) => {
        const openingManager = get().openingManager;
        const newMove = {
          ...move,
          blockEntrainement,
        };

        openingManager.updateMove(newMove);
        set({ treeOpeningData: get().openingManager.getTreeOpeningData() });
      },

      // Fonction pour supprimer un coup
      deleteMove: (move): null | OpeningMove => {
        const openingManager = get().openingManager;
        const newMove = openingManager.deleteMove(move);

        if (newMove) {
          get().loadMove(newMove);

          set({ treeOpeningData: get().openingManager.getTreeOpeningData() });
        }
        return newMove;
      },

      getOpeningTree: () => {
        const openingManager = get().openingManager;
        return openingManager.getOpeningTree();
      },

      getOpeningTreeConverted: () => {
        const tree = get().openingManager.getOpeningTree();

        return TreeManager.convertToTreeData(tree);
      },

      currentMove: config.emptyOpeningMove,

      setCurrentMove: (move) => set({ currentMove: move }),

      loadMove: (moveDetail: OpeningMove) => {
        get().gameChess.loadPosition(moveDetail.history);
        set({ currentMove: moveDetail });
      },

      // Gestion des éléments sur l'échiquier
      setArrows: (arrows) => set({ arrows }),

      resetGame: () =>
        set({
          gameChess: new GameChess(),
          arrows: [],
        }),

      selectedNode: null,
      setSelectedNode: (node: TreeNode | null) => set({ selectedNode: node }),

      setpath: (path) => set({ pathIndex: path }),

      addMultiPath: (nodeStart: TreeNode, nodeFinal: TreeNode) => {
        const state = get();

        if (nodeStart?.move?.history && nodeFinal?.move?.history) {
          console.log(nodeStart?.move?.history, nodeFinal?.move?.history);
          const movesPathShort: OpeningMove[] =
            state.openingManager.findShortestPathBetweenMoves(
              nodeStart.move.history,
              nodeFinal.move.history
            );

          if (movesPathShort[0]?.path?.length >= 1) {
            const areOtherPathsEmpty = movesPathShort
              .slice(1)
              .every((move) => move.path.length === 0);

            if (areOtherPathsEmpty) {
              if (nodeFinal?.move?.children) {
                const childKeys = Object.keys(nodeFinal.move.children);

                if (childKeys.length === 0 || childKeys.length >= 2) {
                  let pathIndex = get().pathIndex;
                  for (const move of movesPathShort) {
                    console.log(move.path[move.path.length - 1] + 1);
                    if (
                      move.path[move.path.length - 1] + 1 !== pathIndex &&
                      !move.path.includes(0)
                    ) {
                      const moveAddPath = {
                        ...move,
                        path: [...move.path, pathIndex],
                      };
                      pathIndex++;
                      state.updateMove(moveAddPath);
                    }
                  }

                  const newInfo = {
                    ...state.infoOpeningData,
                    nbrOfPath: pathIndex - 1, // -1 car on a additionner le dernier path index
                  };
                  state.updateInfoOpeningData(newInfo);
                  state.setpath(pathIndex);

                  return {
                    success: true,
                    message: "Valid path and children conditions met.",
                  };
                } else {
                  return {
                    success: false,
                    message:
                      "NodeFinal has exactly one child, processing halted.",
                  };
                }
              } else {
                return {
                  success: false,
                  message:
                    "NodeFinal is undefined or missing children property.",
                };
              }
            } else {
              return {
                success: false,
                message: "Not all other paths are empty, action halted.",
              };
            }
          } else {
            return {
              success: false,
              message: "First path is empty, action halted.",
            };
          }
        } else {
          return {
            success: false,
            message: "History is missing or undefined in one or both nodes.",
          };
        }
      },

      decrementCurrentPageIndex: () => {
        const state = get();
        const currentPathIndex = state.pathIndex; // Récupérer la page actuelle

        if (currentPathIndex > 1) {
          // Vérifier que le pathIndex est supérieur à 1
          const newPathIndex = currentPathIndex - 1; // Décrémenter le pathIndex
          const move = state.openingManager.getMoveByPage(newPathIndex);
          if (move) {
            const moveDeleteLastPath = {
              ...move,
              path: move.path.filter((item) => item !== newPathIndex),
            };

            state.updateMove(moveDeleteLastPath);
            state.setpath(newPathIndex);
          }
        } else {
          console.warn(
            "Le pathIndex est déjà à 1, aucune décrémentation effectuée."
          );
        }
      },

      checkAllMovesHavePath: () => {
        return get().openingManager.checkAllMovesHavePath();
      },
    }),

    {
      name: config.localStorageCreateOpening, // Nom du store dans le localStorage
      storage: createJSONStorage(() => localStorage), // Utilisez 'storage' au lieu de 'getStorage' // Définit où les données doivent être stockées

      onRehydrateStorage: () => (state) => {
        if (state) {
          state.openingManager = new OpeningManager(state.treeOpeningData);
          state.gameChess = new GameChess();
          state.gameChess.loadFromFEN(state.currentMove.fen);
        }
      },
    }
  )
);

export default useCreateOpeningStore;
