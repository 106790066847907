import { OpeningDataHomePage } from "../types";
import { OpeningInfoCarokann } from "./CaroKann/OpeningInfoCarokann";

const mockOpeningData: OpeningDataHomePage[] = [
  {
    ...OpeningInfoCarokann,
  },
  {
    nom: "Sicilian Defense",
    createur: "Giovanni Morphy",
    level: 3,
    color: "b",
    commentaire:
      "La défense sicilienne est l'une des réponses les plus populaires contre 1.e4, offrant de nombreuses contre-attaques.",
    category: ["Défense", "Classique"],
    history: ["e4", "c5"],
    nbrOfPath: 4,
    path: "/sicilian",
  },
  {
    nom: "Ruy Lopez",
    createur: "Ruy López de Segura",
    level: 2,
    color: "w",
    commentaire:
      "Une ouverture classique où les Blancs exercent une pression sur la défense du Roi noir, avec beaucoup de développement.",
    category: ["Attaque", "Classique"],
    history: ["e4", "e5", "Nf3", "Nc6", "Bb5"],
    nbrOfPath: 3,
    path: "/ruylopez",
  },
  {
    nom: "Italian Game",
    createur: "Gioachino Greco",
    level: 1,
    color: "w",
    commentaire:
      "L'italienne est une ouverture très populaire chez les débutants avec des idées tactiques simples et une rapide activation des pièces.",
    category: ["Attaque", "Débutant"],
    history: ["e4", "e5", "Nf3", "Nc6", "Bc4"],
    nbrOfPath: 2,
    path: "/italiangame",
  },
  {
    nom: "French Defense",
    createur: "André Danican Philidor",
    level: 2,
    color: "b",
    commentaire:
      "La défense française est une ouverture solide qui mène souvent à des structures de pions fermées.",
    category: ["Défense", "Moderne"],
    history: ["e4", "e6", "d4", "d5"],
    nbrOfPath: 3,
    path: "/frenchdefense",
  },
  {
    nom: "King's Indian Defense",
    createur: "Efim Bogoljubov",
    level: 3,
    color: "b",
    commentaire:
      "Une ouverture agressive pour les Noirs, permettant une attaque massive sur l'aile Roi ou une contre-attaque au centre.",
    category: ["Défense", "Hypermoderne"],
    history: ["d4", "Nf6", "c4", "g6"],
    nbrOfPath: 4,
    path: "/kingsindian",
  },
  {
    nom: "Queen's Gambit",
    createur: "Dame Gambit",
    level: 2,
    color: "w",
    commentaire:
      "Le gambit de la dame est une des ouvertures les plus classiques et théoriques, visant à contrôler le centre rapidement.",
    category: ["Gambit", "Classique"],
    history: ["d4", "d5", "c4"],
    nbrOfPath: 3,
    path: "/queensgambit",
  },
  {
    nom: "Vienna Game",
    createur: "Hans Schläger",
    level: 1,
    color: "w",
    commentaire:
      "La partie viennoise permet aux Blancs de contrôler rapidement le centre tout en préparant une attaque f4.",
    category: ["Attaque", "Débutant"],
    history: ["e4", "e5", "Nc3"],
    nbrOfPath: 2,
    path: "/vienna",
  },
  {
    nom: "English Opening",
    createur: "Howard Staunton",
    level: 2,
    color: "w",
    commentaire:
      "L'ouverture anglaise est un moyen flexible et positionnel de commencer la partie, avec des possibilités de contre-attaque.",
    category: ["Attaque", "Moderne"],
    history: ["c4"],
    nbrOfPath: 3,
    path: "/english",
  },
  {
    nom: "Scandinavian Defense",
    createur: "Viswanathan Anand",
    level: 1,
    color: "b",
    commentaire:
      "La défense scandinave est une réponse directe et rapide contre 1.e4, souvent surprenante pour les débutants.",
    category: ["Défense", "Débutant"],
    history: ["e4", "d5"],
    nbrOfPath: 2,
    path: "/scandinavian",
  },
  {
    nom: "Pirc Defense",
    createur: "Vladimir Pirc",
    level: 3,
    color: "b",
    commentaire:
      "Une défense flexible et moderne où les Noirs laissent les Blancs occuper le centre pour mieux le contester plus tard.",
    category: ["Défense", "Hypermoderne"],
    history: ["e4", "d6", "d4", "Nf6", "Nc3", "g6"],
    nbrOfPath: 4,
    path: "/pirc",
  },
];

export default mockOpeningData;
