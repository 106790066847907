import useCreateOpeningStore from "../../store/useCreateOpeningStore";
import OpeningEditor from "./OpeningEditor/OpeningEditor";
import OpeningForm from "./OpeningForm/OpeningForm";

export default function PannelCreateOpening() {
  const { infoOpeningData } = useCreateOpeningStore();

  // Vérifier que toutes les propriétés de infoOpeningData sont renseignées
  const isInfoComplete =
    infoOpeningData.nom !== "" &&
    infoOpeningData.commentaire !== "" &&
    infoOpeningData.category.length !== 0;

  return (
    <>
      {isInfoComplete ? (
        // Si toutes les informations sont complètes, afficher le panneau de création (ou édition)
        <OpeningEditor />
      ) : (
        // Sinon, afficher le formulaire pour saisir les informations
        <OpeningForm />
      )}
    </>
  );
}
