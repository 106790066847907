import React from "react";
import { FaUser } from "react-icons/fa"; // Importation d'icônes
import { IoStar } from "react-icons/io5";
import { useNavigate } from "react-router-dom";
import { defaultPieces } from "../../../assets/svg/pieces";
import { OpeningDataHomePage } from "../../../types";
import ChessboardPresentation from "../ChessboardPresentation/ChessboardPresentation";
import "./OpeningCard.scss";

interface OpeningCardProps {
  dataOpening: OpeningDataHomePage;
}

const pieces = defaultPieces({ width: "25px", height: "25px" });

const OpeningCard: React.FC<OpeningCardProps> = ({ dataOpening }) => {
  const navigate = useNavigate(); // Utiliser useNavigate pour la navigation
  // Fonction pour changer de page

  const handleChangePage = () => {
    navigate(`opening${dataOpening.path}`); // Navigue vers la route dynamique en utilisant le chemin (path)
  };

  return (
    <div className="opening-card" onClick={handleChangePage}>
      <div className="opening-card_content">
        <ChessboardPresentation
          color={dataOpening.color}
          history={dataOpening.history}
        />
      </div>

      <h3 className="opening-card_title">{dataOpening.nom}</h3>

      <div className="opening-card_info">
        {/* Couleur et Niveau sur la même ligne */}
        <div className="opening-card_info-row">
          <div className="opening-card_info-item">
            {/* Générer les étoiles en fonction du niveau */}
            {Array(dataOpening.level)
              .fill(0) // Crée un tableau avec 'level' éléments
              .map((_, index) => (
                <IoStar className="star" key={index} /> // Afficher une étoile pour chaque élément du tableau
              ))}
          </div>
          <div className="opening-card_info-item">
            {dataOpening.color === "w" ? pieces.wP : pieces.bP}
          </div>
        </div>

        {/* Créateur en dessous */}
        <div className="opening-card_info-item opening-card_info-creator">
          <FaUser className="opening-card__icon" />
          <span> by {dataOpening.createur}</span>
        </div>
      </div>
    </div>
  );
};

export default OpeningCard;
