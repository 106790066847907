import { useState } from "react";
import { IoStar } from "react-icons/io5";
import { defaultPieces } from "../../assets/svg/pieces";
import { useSearchOpening } from "../../store/useSearchOpening";
import "./FilterOptions.scss";

const FilterOptions = () => {
  const { setFilterColor, setFilterCategory, setFilterLevel } =
    useSearchOpening();
  const pieces = defaultPieces({ width: "25px", height: "25px" });

  const [selectedColor, setSelectedColor] = useState<string | null>(null);
  const [selectedCategory, setSelectedCategory] = useState<string | null>(null);
  const [hoveredStar, setHoveredStar] = useState<number | null>(null); // Pour gérer le hover
  const [selectedLevel, setSelectedLevel] = useState<number | null>(null); // Niveau d'utilisateur

  const handleColorClick = (color: string) => {
    if (selectedColor === color) {
      setSelectedColor(null);
      setFilterColor(null);
    } else {
      setSelectedColor(color);
      setFilterColor(color);
    }
  };

  const handleCategoryClick = (category: string) => {
    if (selectedCategory === category) {
      setSelectedCategory(null);
      setFilterCategory(null);
    } else {
      setSelectedCategory(category);
      setFilterCategory(category);
    }
  };

  const handleLevelClick = (level: number) => {
    if (selectedLevel === level) {
      setSelectedColor(null);
      setFilterLevel(null);
    } else {
      setSelectedLevel(level);
      setFilterLevel(level);
    }
  };

  const handleMouseEnter = (level: number) => {
    setHoveredStar(level); // Définir quelle étoile est survolée
  };

  const handleMouseLeave = () => {
    setHoveredStar(null); // Réinitialiser lorsque la souris quitte
  };

  return (
    <div className="filter-options">
      {/* Boutons de couleur */}
      <div className="filter-options_color">
        <button
          onClick={() => handleColorClick("white")}
          className={`color-button ${
            selectedColor === "white" ? "active" : ""
          }`}
          aria-label="white"
        >
          {pieces.wP}
        </button>
        <button
          onClick={() => handleColorClick("black")}
          className={`color-button ${
            selectedColor === "black" ? "active" : ""
          }`}
          aria-label="black"
        >
          {pieces.bP}
        </button>

        {/* Étoiles pour le niveau */}
        <div className="filter-options_stars">
          {[1, 2, 3].map((level) => (
            <span
              key={level}
              className={`star ${
                hoveredStar && level <= hoveredStar ? "hovered" : ""
              } ${selectedLevel && level <= selectedLevel ? "filled" : ""}`}
              onClick={() => handleLevelClick(level)}
              onMouseEnter={() => handleMouseEnter(level)}
              onMouseLeave={handleMouseLeave}
            >
              <IoStar />
            </span>
          ))}
        </div>
      </div>

      {/* Boutons de catégorie plus simples */}
      <div className="filter-options_category">
        <button
          onClick={() => handleCategoryClick("Populaires")}
          className={selectedCategory === "Populaires" ? "active" : ""}
        >
          Populaires
        </button>
        <button
          onClick={() => handleCategoryClick("Gambit")}
          className={selectedCategory === "Gambit" ? "active" : ""}
        >
          Gambit
        </button>
        <button
          onClick={() => handleCategoryClick("Classiques")}
          className={selectedCategory === "Classiques" ? "active" : ""}
        >
          Classiques
        </button>
      </div>
    </div>
  );
};

export default FilterOptions;
