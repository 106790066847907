import React from "react";
import ChessboardCours from "../commun/ChessboardCours/ChessboardCours";

import { useLessonOpeningStore } from "../../store/useLessonOpeningStore";
import Panel from "../commun/Panel/Panel";
import TreeAndMoveDetails from "../TreeComment/TreeAndMoveDetails";
import "./ChessboardCoursEntrainement.scss";

const ChessboardCoursEntrainement: React.FC = () => {
  const { infoOpeningData } = useLessonOpeningStore();

  return (
    <>
      <div className="chessboardCoursEntrainement">
        <h1 className="chessboardCoursEntrainement_title">
          {infoOpeningData.nom}
        </h1>
        <div className="chessboardCoursEntrainement_content">
          <div className="chessboardCoursEntrainement_content_openingDetailsChessboard">
            <ChessboardCours />

            <div className="openingDetails">
              <TreeAndMoveDetails />
            </div>
          </div>
        </div>
        <Panel />
      </div>
    </>
  );
};

export default ChessboardCoursEntrainement;
