import React, { useCallback, useEffect, useRef, useState } from "react";
import { Chessboard } from "react-chessboard";
import "./ChessboardSizeHandler.scss";

interface ChessboardSizeHandlerProps {
  position: string;
  customArrows: any;
  arePiecesDraggable: boolean;
  onPieceDrop?: (sourceSquare: string, targetSquare: string) => boolean;
  onSquareClick: (square: string) => void;
  boardOrientation: "white" | "black";

  resizable?: boolean;
  createArrow: boolean;
  customSquareStyles?: any;
}

const ChessboardSizeHandler: React.FC<ChessboardSizeHandlerProps> = ({
  position,
  customArrows,
  arePiecesDraggable,
  onPieceDrop,
  onSquareClick,
  boardOrientation,
  customSquareStyles,
  resizable = true,
  createArrow = true,
}) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const [boardSizeUser, setBoardSizeUser] = useState<number | null>(null);
  const boardSizeUserRef = useRef<number | null>(null);
  const [boardSize, setBoardSize] = useState<number>(300);

  const resizing = useRef(false);
  const lastPosition = useRef<{ x: number; y: number } | null>(null);

  const [isPortrait, setIsPortrait] = useState(
    window.innerHeight > window.innerWidth
  );

  useEffect(() => {
    const handleResize = () => {
      setIsPortrait(window.innerHeight > window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    boardSizeUserRef.current = boardSizeUser;
  }, [boardSizeUser]);

  const handleStart = (event: React.MouseEvent | React.TouchEvent) => {
    if (!resizable) return;
    resizing.current = true;
    const clientX =
      "touches" in event ? event.touches[0].clientX : event.clientX;
    const clientY =
      "touches" in event ? event.touches[0].clientY : event.clientY;
    lastPosition.current = { x: clientX, y: clientY };
    event.preventDefault();
  };

  const handleMove = useCallback((event: MouseEvent | TouchEvent) => {
    if (!resizing.current || !lastPosition.current || !containerRef.current)
      return;

    const clientX =
      event instanceof TouchEvent ? event.touches[0].clientX : event.clientX;
    const clientY =
      event instanceof TouchEvent ? event.touches[0].clientY : event.clientY;

    const deltaX = clientX - lastPosition.current.x;
    const deltaY = clientY - lastPosition.current.y;

    const delta = (deltaX + deltaY) / 2;

    const grandParentElement =
      containerRef.current.parentElement?.parentElement;
    if (!grandParentElement) return;

    const parentElement = containerRef.current.parentElement;
    const siblingElements = Array.from(parentElement!.children).filter(
      (child) => child !== containerRef.current
    );

    const grandParentComputedStyle = getComputedStyle(grandParentElement);
    const paddingLeft = parseFloat(grandParentComputedStyle.paddingLeft) || 0;
    const paddingRight = parseFloat(grandParentComputedStyle.paddingRight) || 0;
    const paddingTop = parseFloat(grandParentComputedStyle.paddingTop) || 0;
    const paddingBottom =
      parseFloat(grandParentComputedStyle.paddingBottom) || 0;

    const parentWidth =
      grandParentElement.clientWidth - paddingLeft - paddingRight;
    const parentHeight =
      grandParentElement.clientHeight - paddingTop - paddingBottom;

    const parentComputedStyle = getComputedStyle(parentElement!);
    const flexDirection = parentComputedStyle.flexDirection;

    let totalSiblingMinSize = 0;

    siblingElements.forEach((sibling) => {
      const siblingStyle = getComputedStyle(sibling);
      const minWidth = parseFloat(siblingStyle.minWidth) || 0;
      const minHeight = parseFloat(siblingStyle.minHeight) || 0;

      totalSiblingMinSize +=
        flexDirection === "row" || flexDirection === "row-reverse"
          ? minWidth
          : minHeight;
    });

    const maxBoardSize =
      (flexDirection === "row" || flexDirection === "row-reverse"
        ? parentWidth
        : parentHeight) - totalSiblingMinSize;

    setBoardSize((prevSize) => {
      let newSize = prevSize + delta;
      newSize = Math.max(100, Math.min(newSize, maxBoardSize));
      setBoardSizeUser(newSize);
      return newSize;
    });

    lastPosition.current = { x: clientX, y: clientY };
  }, []);

  const handleEnd = useCallback(() => {
    resizing.current = false;
    lastPosition.current = null;
  }, []);

  const handleWindowResize = useCallback(() => {
    if (!containerRef.current) {
      console.log("ContainerRef est indéfini");
      return;
    }

    const grandParentElement =
      containerRef.current.parentElement?.parentElement;
    if (!grandParentElement) {
      console.log("GrandParentElement est indéfini");
      return;
    }

    const computedStyle = getComputedStyle(grandParentElement);
    const paddingLeft = parseFloat(computedStyle.paddingLeft) || 0;
    const paddingRight = parseFloat(computedStyle.paddingRight) || 0;
    const paddingTop = parseFloat(computedStyle.paddingTop) || 0;
    const paddingBottom = parseFloat(computedStyle.paddingBottom) || 0;

    const parentWidth =
      grandParentElement.clientWidth - paddingLeft - paddingRight;
    const parentHeight =
      grandParentElement.clientHeight - paddingTop - paddingBottom;

    let availableWidth = parentWidth;
    let availableHeight = parentHeight;

    const parentElement = containerRef.current.parentElement;
    const siblingElements = Array.from(parentElement!.children).filter(
      (child) => child !== containerRef.current
    );

    const parentComputedStyle = getComputedStyle(parentElement!);
    const flexDirection = parentComputedStyle.flexDirection;

    let totalSiblingMinSize = 0;

    siblingElements.forEach((sibling) => {
      const siblingStyle = getComputedStyle(sibling);
      const minWidth = parseFloat(siblingStyle.minWidth) || 0;
      const minHeight = parseFloat(siblingStyle.minHeight) || 0;

      totalSiblingMinSize +=
        flexDirection === "row" || flexDirection === "row-reverse"
          ? minWidth
          : minHeight;
    });

    if (flexDirection === "row" || flexDirection === "row-reverse") {
      availableWidth -= totalSiblingMinSize;
    } else {
      availableHeight -= totalSiblingMinSize + 5;
    }

    availableWidth = Math.max(availableWidth, 0);
    availableHeight = Math.max(availableHeight, 0);

    const maxBoardSize = Math.floor(Math.min(availableWidth, availableHeight));

    if (resizable && boardSizeUserRef.current !== null) {
      const adjustedSize = Math.min(boardSizeUserRef.current, maxBoardSize);
      const integerBoardSize = Math.round(adjustedSize);
      setBoardSize(integerBoardSize);
    } else {
      setBoardSize(maxBoardSize);
    }
  }, [resizable]);

  useEffect(() => {
    const observer = new ResizeObserver(() => {
      handleWindowResize();
    });

    if (containerRef.current) {
      const grandParentElement =
        containerRef.current.parentElement?.parentElement;
      if (grandParentElement) {
        observer.observe(grandParentElement);
      }
    }

    return () => {
      observer.disconnect();
    };
  }, [handleWindowResize]);

  useEffect(() => {
    if (resizable) {
      window.addEventListener("mousemove", handleMove);
      window.addEventListener("mouseup", handleEnd);
      window.addEventListener("touchmove", handleMove);
      window.addEventListener("touchend", handleEnd);

      return () => {
        window.removeEventListener("mousemove", handleMove);
        window.removeEventListener("mouseup", handleEnd);
        window.removeEventListener("touchmove", handleMove);
        window.removeEventListener("touchend", handleEnd);
      };
    }
  }, [handleMove, handleEnd, resizable]);

  useEffect(() => {
    handleWindowResize();
  }, [handleWindowResize]);

  return (
    <div
      className="resizable-chessboard-container"
      style={{
        width: boardSize,
        height: boardSize,
        position: "relative",
        display: "inline-block",
      }}
      ref={containerRef}
    >
      <Chessboard
        position={position}
        boardWidth={boardSize}
        customArrows={customArrows}
        arePiecesDraggable={arePiecesDraggable}
        onPieceDrop={onPieceDrop}
        onSquareClick={onSquareClick}
        boardOrientation={boardOrientation}
        areArrowsAllowed={createArrow}
        customSquareStyles={customSquareStyles}
      />
      {resizable && (
        <div
          className="resize-handle"
          onMouseDown={handleStart}
          onTouchStart={handleStart}
        ></div>
      )}
    </div>
  );
};

export default ChessboardSizeHandler;
