import axios from "axios";
import React, { useEffect, useState } from "react";
import { IoIosAddCircle } from "react-icons/io";
import { IoMailOpenOutline, IoPersonCircleOutline } from "react-icons/io5";
import {
  MdMenu,
  MdOutlineAdminPanelSettings,
  MdOutlineWorkspacePremium,
} from "react-icons/md";
import { Menu, MenuItem, Sidebar, SubMenu } from "react-pro-sidebar";
import { Link } from "react-router-dom";
import icons from "../../../assets/svg/icons";
import { useLogin } from "../../../contexts/checkLoginAndModal";
import { useUserStore } from "../../../store/useUserStore";
import "./SidebarCustom.scss";

const API_URL = process.env.REACT_APP_API_URL || "";

export function SidebarCustom() {
  const { isLogged, setIsLogged, isAdmin, pseudo } = useUserStore();
  const [toggled, setToggled] = React.useState(false);
  const { checkLoginAndModal } = useLogin();
  const [buildNumber, setBuildNumber] = useState("Loading...");

  useEffect(() => {
    const herokuBuildNumber =
      process.env.REACT_APP_HEROKU_RELEASE_VERSION || "N/A";
    setBuildNumber(herokuBuildNumber);
  }, []);

  // Fonction pour fermer la sidebar en cliquant à l'extérieur
  const closeSidebar = () => {
    setToggled(false);
  };

  const handleConnected = async () => {
    if (isLogged) {
      try {
        // Appeler l'API de déconnexion pour supprimer le cookie
        await axios.post(`${API_URL}/api/logout`);
        setIsLogged(false); // Mettre à jour l'état local
      } catch (error) {
        console.error("Erreur lors de la déconnexion :", error);
      }
    } else {
      checkLoginAndModal();
    }
  };

  return (
    <>
      {/* Bouton pour ouvrir/fermer la sidebar */}
      <div className="sidebarCustom_menu" onClick={() => setToggled(!toggled)}>
        <MdMenu />
      </div>

      {/* Afficher un backdrop transparent pour fermer en cliquant à l'extérieur */}
      {toggled && <div className="sb-backdrop" onClick={closeSidebar} />}

      {/* Sidebar qui passe au-dessus du contenu */}
      <div className={`sb-sidebar ${toggled ? "active" : ""}`}>
        <Sidebar>
          <Menu className="sb-menu">
            <MenuItem className="sb-menu-item">
              <h3>LOGO Learn Openings</h3>
            </MenuItem>

            <MenuItem
              icon={<IoPersonCircleOutline name="bar-chart" />}
              className="sb-menu-item"
            >
              Profil
            </MenuItem>

            <MenuItem
              icon={
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  version="1.1"
                  id="Uploaded to svgrepo.com"
                  width="20px"
                  height="20px"
                  viewBox="0 0 32 32"
                >
                  <style type="text/css"></style>
                  <path
                    className="feather_een"
                    d="M29.906,12.916c0.799-0.349,0.799-1.483,0-1.832L17.202,5.526C16.819,5.358,16.41,5.275,16,5.275  c-0.41,0-0.819,0.084-1.202,0.252L2.094,11.084c-0.799,0.35-0.799,1.483,0,1.832L8,15.5v3.32c0,1.136,0.642,2.175,1.658,2.683l0,0  C11.655,22.501,13.827,23,16,23s4.345-0.499,6.341-1.497l0,0C23.358,20.995,24,19.956,24,18.82V15.5l4-1.75V21c-0.552,0-1,0.448-1,1  v3c0,0.552,0.448,1,1,1h1c0.552,0,1-0.448,1-1v-3c0-0.552-0.448-1-1-1v-7.688L29.906,12.916z M23,18.82  c0,0.762-0.424,1.448-1.106,1.789C20.074,21.519,18.035,22,16,22s-4.074-0.481-5.894-1.391C9.424,20.268,9,19.582,9,18.82v-2.882  l5.798,2.536c0.383,0.168,0.793,0.252,1.202,0.252c0.41,0,0.819-0.084,1.202-0.252L23,15.937V18.82z M16.802,17.558  c-0.254,0.111-0.524,0.168-0.802,0.168s-0.547-0.056-0.802-0.168L2.495,12l12.703-5.558C15.453,6.331,15.722,6.275,16,6.275  s0.547,0.056,0.802,0.168L29.505,12l-1.118,0.489L16.894,11.57C16.732,11.235,16.396,11,16,11c-0.552,0-1,0.448-1,1  c0,0.552,0.448,1,1,1c0.337,0,0.621-0.178,0.802-0.434l9.646,0.772L16.802,17.558z"
                  />
                </svg>
              }
              className="sb-menu-item"
            >
              <Link to="/" style={{ textDecoration: "none", color: "inherit" }}>
                Openings
              </Link>
            </MenuItem>

            <MenuItem
              icon={<MdOutlineWorkspacePremium />}
              className="sb-menu-item"
            >
              <Link
                to="/payment"
                style={{ textDecoration: "none", color: "inherit" }}
              >
                Premium
              </Link>
            </MenuItem>

            <MenuItem
              icon={<IoMailOpenOutline name="bar-chart" />}
              className="sb-menu-item"
            >
              Contact us
            </MenuItem>
            {isAdmin ? (
              <SubMenu
                className="sb-menu-item"
                icon={<MdOutlineAdminPanelSettings />}
                label="Admin"
              >
                <MenuItem icon={<IoIosAddCircle />} className="sb-menu-item">
                  <Link
                    to="/createOpening"
                    style={{ textDecoration: "none", color: "inherit" }}
                  >
                    Add Opening
                  </Link>
                </MenuItem>
              </SubMenu>
            ) : null}
          </Menu>

          <div className="sb-loginDisconnect-version">
            <div
              onClick={handleConnected}
              className={isLogged ? "disconnect-section" : "login-section"}
            >
              {isLogged ? (
                <>
                  {icons.disconnect}
                  {pseudo}{" "}
                </>
              ) : (
                <>{icons.login} Login</>
              )}
              <div
                className={`${isLogged ? "connected" : "disconnected"}`}
              ></div>
            </div>

            <div className="legal-section">
              Mention legal ------- Build {buildNumber}
            </div>
          </div>

          {/* Version du site */}
        </Sidebar>
      </div>
    </>
  );
}
