import { IoEyeOffSharp } from "react-icons/io5";

import "./MoveNoDetails.scss";
export default function MoveNoDetails() {
  return (
    <div className="moveNoDetails_eyeOff">
      <IoEyeOffSharp />
    </div>
  );
}
