import axios from "axios";
import { create } from "zustand"; // Assure-toi d'utiliser cette importation

const API_URL = process.env.REACT_APP_API_URL || "";

interface UserState {
  isLogged: boolean;
  isAdmin: boolean;
  email: string | null;
  name: string | null;
  picture: string | null;
  isPremium: true | false;
  pseudo: string | null;
  subscriptionExpiry: Date | null;
  setSubscriptionExpiry: (expiry: Date) => void;
  setIsLogged: (isLogged: boolean) => void;
  checkLoginStatus: () => Promise<boolean>;
  checkPremiumStatus: () => Promise<boolean>;
}

export const useUserStore = create<UserState>((set) => ({
  // Initial state
  isLogged: false,
  isAdmin: false,
  isPremium: false,
  email: null,
  name: null,
  picture: null,
  pseudo: null,
  subscriptionExpiry: null, // Initialisez avec null
  setIsLogged: (isLogged: boolean) => {
    set({ isLogged });
  },
  setSubscriptionExpiry: (expiry: Date) => {
    set({ subscriptionExpiry: expiry });
  },
  // Ajoute une fonction pour vérifier et stocker l'état premium
  checkPremiumStatus: async () => {
    try {
      // Appelle l'API une fois pour récupérer le statut premium
      const response = await axios.get(`${API_URL}/api/premium-status`, {
        withCredentials: true,
      });

      const { isPremium } = response.data;
      set({ isPremium }); // Met à jour l'état avec la valeur reçue du serveur

      return isPremium;
    } catch (error) {
      console.error("Error checking premium status:", error);
      set({ isPremium: false });
      return false;
    }
  },

  // Function to check if the user is logged in
  checkLoginStatus: async () => {
    try {
      // Request user info from the backend
      const response = await axios.get(`${API_URL}/api/user-info`, {
        withCredentials: true,
      });

      const userInfo = response.data;

      set({
        isLogged: true,
        isAdmin: userInfo.admin,
        email: userInfo.email,
        name: userInfo.name,
        pseudo: userInfo.pseudo,
        picture: userInfo.picture,
        isPremium: userInfo.isPremium,
        subscriptionExpiry: userInfo.subscriptionExpiry,
      });

      return true;
    } catch (error) {
      console.error("Error checking login status:", error);
      set({
        isLogged: false,
        isAdmin: false,
        email: null,
        name: null,
        picture: null,
      });
      return false;
    }
  },
}));
