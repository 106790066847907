import { Comments } from "../../types";

export const CommentCarokann: Comments = {
  "rnbqkbnr/pp1ppppp/2p5/8/4P3/8/PPPP1PPP/RNBQKBNR w KQkq - 0 2": {
    fr: "Les Noirs imposent la défense Caro-Kann.",
  },
  "rnbqkbnr/pp1ppppp/2p5/8/3PP3/8/PPP2PPP/RNBQKBNR b KQkq - 0 2": {
    fr: "Le coup naturel des Blancs pour prendre le contrôle du centre.",
  },
  "rnbqkbnr/pp2pppp/2p5/3p4/3PP3/8/PPP2PPP/RNBQKBNR w KQkq - 0 3": {
    fr: "Les Noirs contestent le centre et attaquent le pion en e4.",
  },
  "rnbqkbnr/pp2pppp/2p5/3p4/3PP3/2N5/PPP2PPP/R1BQKBNR b KQkq - 1 3": {
    fr: "Les Blancs défendent le pion en e4 avec l'idée de placer un cavalier au centre.",
  },
  "rnbqkbnr/pp2pppp/2p5/3p4/3PP3/3B4/PPP2PPP/RNBQK1NR b KQkq - 1 3": {
    fr: "Les Blancs défendent le pion en e4 avec le fou en d3, ce qui n'est pas vraiment un bon coup.",
  },
  "rnbqkbnr/pp2pppp/2p5/8/3Pp3/3B4/PPP2PPP/RNBQK1NR w KQkq - 0 4": {
    fr: "Les Noirs mangent le pion, et les Blancs doivent reprendre le pion.",
  },
  "rnbqkb1r/pp2pppp/2p2n2/8/3PB3/8/PPP2PPP/RNBQK1NR w KQkq - 1 5": {
    fr: "Les Noirs développent le cavalier tout en gagnant un temps sur les Blancs.",
  },
  "rnbqkbnr/pp2pppp/2p5/3p4/3PP3/5Q2/PPP2PPP/RNB1KBNR b KQkq - 1 3": {
    fr: "Les Blancs défendent le pion e4, mais exposent leur Dame trop tôt en contrepartie.",
  },
  "rnbqkb1r/pp2pppp/2p2n2/8/3PQ3/8/PPP2PPP/RNB1KBNR w KQkq - 1 5": {
    fr: "Après cet échange de coups, les Noirs développent le cavalier tout en gagnant un temps sur la Dame.",
  },
  "rnbqkbnr/pp2pppp/2p5/3P4/3P4/8/PPP2PPP/RNBQKBNR b KQkq - 0 3": {
    fr: "Les Blancs choisissent la variante d'échange.",
  },
  "rnbqkbnr/pp2pppp/8/3p4/3P4/8/PPP2PPP/RNBQKBNR w KQkq - 0 4": {
    fr: "Après l'échange, nous avons une position symétrique et égale, non dangereuse pour les Noirs.",
  },
  "r1bqkbnr/pp2pppp/2n5/3p4/3P4/3B4/PPP2PPP/RNBQK1NR w KQkq - 2 5": {
    fr: "Les Noirs développent leur cavalier. Nous allons ensuite développer nos pièces harmonieusement.",
  },
  "rnbqkbnr/pp2pppp/8/3p4/3P4/3B4/PPP2PPP/RNBQK1NR b KQkq - 1 4": {
    fr: "Les Blancs développent leur fou.",
  },
  "rnbqkbnr/pp2pppp/8/3p4/2PP4/8/PP3PPP/RNBQKBNR b KQkq - 0 4": {
    fr: "Attaque Panov : attaque le pion d5.",
  },
  "rnbqkb1r/pp2pppp/5n2/3p4/2PP4/8/PP3PPP/RNBQKBNR w KQkq - 1 5": {
    fr: "Coup de développement naturel pour protéger le pion et éviter le développement du fou adverse.",
  },
  "rnbqkb1r/pp2pppp/5n2/3p4/2PP4/2N5/PP3PPP/R1BQKBNR b KQkq - 2 5": {
    fr: "Les Blancs développent le cavalier et doublent l'attaque sur le pion d5.",
  },
  "rnbqkb1r/pp3ppp/4pn2/3p4/2PP4/2N5/PP3PPP/R1BQKBNR w KQkq - 0 6": {
    fr: "Les Noirs défendent le d5 avec le pion en e6. Ensuite, le plan des Noirs est le développement des pièces et de faire le petit roque rapidement.",
  },
  "rnbqkbnr/pp2pppp/2p5/8/3Pp3/2N5/PPP2PPP/R1BQKBNR w KQkq - 0 4": {
    fr: "Le seul coup correct pour les Noirs est la prise en e4.",
  },
  "rnbqkbnr/pp2pppp/2p5/8/3PN3/8/PPP2PPP/R1BQKBNR b KQkq - 0 4": {
    fr: "Les Blancs ont un cavalier au centre, ils contrôlent des cases importantes.",
  },
  "rnbqkbnr/pp2pppp/8/1B1p4/3P4/8/PPP2PPP/RNBQK1NR b KQkq - 1 4": {
    fr: "Échec au roi. C'est un mauvais coup car les Noirs peuvent échanger leur fou sur la case blanche.",
  },
  "rn1qkbnr/pp1bpppp/8/1B1p4/3P4/8/PPP2PPP/RNBQK1NR w KQkq - 2 5": {
    fr: "Pas de commentaire.",
  },
  "rn2kbnr/pp1qpppp/8/3p4/3P4/8/PPP2PPP/RNBQK1NR w KQkq - 0 6": {
    fr: "Après que la Dame prenne le fou, on se débarrasse potentiellement de notre mauvais fou après e6. Si notre fou blanc était encore en jeu, il serait bloqué par la chaîne de pions.",
  },
  "rn1qkbnr/pp2pppp/2p5/3p4/3PN3/8/PPP2PPP/R1BQKBNR w KQkq - 1 5": {
    fr: "On sort notre fou blanc pour attaquer le cavalier.",
  },
  "rn1qkbnr/pp2pppp/2p5/5b2/3PN3/3B4/PPP2PPP/R1BQK1NR b KQkq - 2 5": {
    fr: "Le fou protège le cavalier.",
  },
  "rn2kbnr/pp2pppp/2p5/5b2/3qN3/3B4/PPP2PPP/R1BQK1NR w KQkq - 0 6": {
    fr: "Avec les Noirs, on gagne le pion en d4, on se retrouve avec un pion de plus.",
  },
  "rn1qkbnr/pp2pppp/2p5/5b2/3P4/6N1/PPP2PPP/R1BQKBNR b KQkq - 2 5": {
    fr: "Le meilleur coup des Blancs, ils repositionnent leur cavalier pour attaquer le fou des Noirs.",
  },
  "rn1qkbnr/pp2pppp/2p3b1/8/3P4/6N1/PPP2PPP/R1BQKBNR w KQkq - 3 6": {
    fr: "Les Noirs repositionnent leur fou et restent sur la grande diagonale, obtenant ainsi un fou puissant.",
  },
  "r2qkb1r/pp1n1ppp/2p1pnb1/8/3P4/5NN1/PPP1BPPP/R1BQ1RK1 w kq - 2 9": {
    fr: "Après des coups de développement normaux, nous avons une position solide pour les Noirs. Les deux pions centraux contrôlent le centre, et le fou des Blancs contrôle la grande diagonale.",
  },
  "rn1qkbnr/pp2pppp/2p5/3p4/3P3P/6N1/PPP2PP1/R1BQKBNR b KQkq - 0 6": {
    fr: "Un coup intéressant pour les Blancs avec l'idée de jouer h5 pour attaquer le fou.",
  },
  "rn1qkbnr/pp2ppp1/2p3bp/8/3P3P/6N1/PPP2PP1/R1BQKBNR w KQkq - 0 7": {
    fr: "Les Noirs créent une case de repli pour le fou en h7.",
  },
  "rnbqkbnr/pp2pppp/2p5/3pP3/3P4/8/PPP2PPP/RNBQKBNR b KQkq - 0 3": {
    fr: "La variante d'avance prend la case de développement naturelle du cavalier.",
  },
  "rn1qkbnr/pp2pppp/2p5/3pPb2/3P4/8/PPP2PPP/RNBQKBNR w KQkq - 1 4": {
    fr: "Les Noirs sortent leur fou avant qu'il ne soit enfermé par la chaîne de pions.",
  },
  "rn1qkbnr/pp3ppp/2p1p3/3pPb2/3P4/3B1N2/PPP2PPP/RNBQK2R b KQkq - 1 5": {
    fr: "Les Blancs proposent un échange de fous.",
  },
  "rn1qkbnr/pp3ppp/2p1p3/3pP3/3P4/3b1N2/PPP2PPP/RNBQK2R w KQkq - 0 6": {
    fr: "Si l'on accepte l'échange, on se retrouve avec une position centrale solide malgré une faible prise d'espace.",
  },
  "rn1qkbnr/pp2pppp/2p5/3pPb2/3P3P/8/PPP2PP1/RNBQKBNR b KQkq - 0 4": {
    fr: "Un coup très dangereux pour les Noirs.",
  },
  "rn1qkbnr/pp3ppp/2p1p3/3pPb2/3P3P/8/PPP2PP1/RNBQKBNR w KQkq - 0 5": {
    fr: "Si les Noirs jouent e6, c'est le drame, ils perdent une pièce après.",
  },
  "rn1qkbnr/pp3ppp/2p1p3/3pP2P/3P2P1/5Q2/PPP5/RNB1KBNR b KQkq - 0 8": {
    fr: "Et les Blancs se retrouvent avec une pièce de plus.",
  },
  "rn1qkbnr/pp2ppp1/2p4p/3pPb2/3P3P/8/PPP2PP1/RNBQKBNR w KQkq - 0 5": {
    fr: "Le bon coup est h6 pour avoir une case de repli pour le fou.",
  },
};
