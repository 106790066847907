// PaymentPage.tsx

import React, { useEffect, useState } from 'react';
import PaymentForm from './PaymentForm';
import { useUserStore } from '../../store/useUserStore';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { useLogin } from '../../contexts/checkLoginAndModal';
import {
  Container,
  Typography,
  Box,
  Button,
  Card,
  CardContent,
  CardMedia,
  Grid,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Alert,
} from '@mui/material';
import StarIcon from '@mui/icons-material/Star';
import axios from 'axios';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY || '');
const API_URL = process.env.REACT_APP_API_URL || "";

const PaymentPage: React.FC = () => {
  const { isPremium, subscriptionExpiry, isLogged } = useUserStore();
  const { checkLoginAndModal } = useLogin();
  const [openDialog, setOpenDialog] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [message, setMessage] = useState('');
  const [isUnsubscribed, setIsUnsubscribed] = useState(false);

  useEffect(() => {
    if (!isLogged) {
      checkLoginAndModal();
    }
  }, [isLogged, checkLoginAndModal]);

  const handleUnsubscribe = async () => {
    setIsLoading(true);
    setMessage('');

    try {
      // Appel à la route d'annulation d'abonnement
      const response = await axios.post(`${API_URL}/api/unsubscribe`, {}, {
        withCredentials: true,
      });

      setMessage(response.data.message);
      setIsUnsubscribed(true);
      setOpenDialog(false);
    } catch (error: any) {
      if (error.response && error.response.data && error.response.data.error) {
        setMessage(error.response.data.error);
      } else {
        setMessage("Une erreur est survenue lors de l'annulation de l'abonnement.");
      }
    } finally {
      setIsLoading(false);
    }
  };

  const confirmUnsubscribe = () => {
    setOpenDialog(true);
  };

  const closeDialog = () => {
    setOpenDialog(false);
  };

  if (!isLogged) {
    return (
      <Container maxWidth="sm" sx={{ textAlign: 'center', mt: 5 }}>
        <Typography variant="h5" gutterBottom>
          Veuillez vous connecter pour accéder à la page de paiement.
        </Typography>
        <Button variant="contained" color="primary" onClick={checkLoginAndModal}>
          Se Connecter
        </Button>
      </Container>
    );
  }

  if (isPremium) {
    const remainingDays = subscriptionExpiry
      ? Math.ceil((new Date(subscriptionExpiry).getTime() - Date.now()) / (1000 * 60 * 60 * 24))
      : null;

    return (
      <Container maxWidth="sm" sx={{ mt: 5 }}>
        <Card>
          <CardContent sx={{ textAlign: 'center' }}>
            <StarIcon color="primary" sx={{ fontSize: 60 }} />
            <Typography variant="h5" gutterBottom>
              Vous êtes déjà un membre Premium !
            </Typography>
            {remainingDays !== null && (
              <Typography variant="body1">
                Il vous reste <strong>{remainingDays} jours</strong> de votre abonnement premium.
              </Typography>
            )}
            <Typography variant="body2" color="textSecondary" sx={{ mt: 2 }}>
              Merci de votre soutien continu.
            </Typography>
            {!isUnsubscribed && (
              <Button
                variant="outlined"
                color="secondary"
                sx={{ mt: 3 }}
                onClick={confirmUnsubscribe}
                disabled={isLoading}
              >
                {isLoading ? 'Traitement...' : 'Se Désabonner'}
              </Button>
            )}
          </CardContent>
        </Card>

        {/* Unsubscribe Confirmation Dialog */}
        <Dialog open={openDialog} onClose={closeDialog}>
          <DialogTitle>Confirmer la Désinscription</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Êtes-vous sûr de vouloir vous désabonner de votre compte premium ? Vous perdrez l'accès aux fonctionnalités exclusives.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={closeDialog} color="primary">
              Annuler
            </Button>
            <Button onClick={handleUnsubscribe} color="secondary" autoFocus disabled={isLoading}>
              {isLoading ? 'Traitement...' : 'Se Désabonner'}
            </Button>
          </DialogActions>
        </Dialog>

        {message && (
          <Box mt={2}>
            <Alert severity={isUnsubscribed ? 'success' : 'error'}>
              {message}
            </Alert>
          </Box>
        )}
      </Container>
    );
  }

  return (
    <Container maxWidth="md" sx={{ mt: 5 }}>
      <Grid container spacing={4} alignItems="center">
        <Grid item xs={12} md={6}>
          <Typography variant="h4" gutterBottom>
            Devenez Premium
          </Typography>
          <Typography variant="body1" gutterBottom>
            Profitez de fonctionnalités exclusives en devenant membre premium.
          </Typography>
          <ul>
            <li>Accès illimité aux contenus premium</li>
            <li>Support prioritaire</li>
            <li>Expérience sans publicité</li>
            <li>Et bien plus encore...</li>
          </ul>
          <Box sx={{ mt: 3 }}>
            <Elements stripe={stripePromise}>
              <PaymentForm />
            </Elements>
          </Box>
        </Grid>
        <Grid item xs={12} md={6}>
          <CardMedia
            component="img"
            alt="Avantages du compte premium"
            sx={{ borderRadius: 2 }}
          />
        </Grid>
      </Grid>
    </Container>
  );
};

export default PaymentPage;