import { create } from "zustand";

type AnnotationType =
  | "error"
  | "warning"
  | "success"
  | "circle"
  | "arrow"
  | "legalMove"
  | "check";

type Annotation = {
  position: string;
  type: AnnotationType;
  color?: string; // Couleur pour les cercles et les flèches
  from?: string; // Position de départ pour les flèches
  to?: string; // Position d'arrivée pour les flèches
};

interface AnnotationsState {
  annotations: Annotation[];
  addAnnotation: (
    position: string,
    type: AnnotationType,
    color?: string,
    from?: string,
    to?: string
  ) => void;
  removeAnnotationByPosition: (position: string) => void;
  removeAllAnnotations: () => void;
  getAnnotations: () => Annotation[];
  generateCustomSquareStyles: () => Record<string, any>; // Ajout de la méthode pour générer les styles
}

export const useAnnotationsStore = create<AnnotationsState>((set, get) => ({
  annotations: [],

  // Ajouter une annotation
  addAnnotation: (position, type, color, from, to) => {
    set((state) => ({
      annotations: [...state.annotations, { position, type, color, from, to }],
    }));
  },

  // Supprimer une annotation par position
  removeAnnotationByPosition: (position) =>
    set((state) => ({
      annotations: state.annotations.filter(
        (annotation) => annotation.position !== position
      ),
    })),

  // Supprimer toutes les annotations
  removeAllAnnotations: () => set(() => ({ annotations: [] })),

  // Obtenir toutes les annotations
  getAnnotations: () => {
    const state = get();
    return state.annotations;
  },

  // Générer les styles personnalisés pour les cases en fonction des annotations
  generateCustomSquareStyles: () => {
    const state = get();

    return state.annotations.reduce((styles: any, annotation: any) => {
      // Gestion des erreurs et succès avec un arrière-plan coloré
      if (annotation.type === "error" || annotation.type === "success") {
        styles[annotation.position] = {
          backgroundColor:
            annotation.type === "error"
              ? "rgba(255, 0, 0, 0.5)" // Fond rouge semi-transparent pour les erreurs
              : "hsl(34, 78%, 60%,0.7)", // Fond bleu semi-transparent pour les succès
        };
      }

      if (annotation.type === "check") {
      }

      // // Gestion des cercles avec une bordure circulaire autour de la case
      // if (annotation.type === "circle" && annotation.color) {
      //   styles[annotation.position] = {
      //     backgroundColor: "transparent", // Aucun fond
      //     border: `2px solid ${annotation.color}`, // Bordure circulaire avec la couleur spécifiée
      //     borderRadius: "50%", // Pour obtenir un cercle
      //     zIndex: 99, // Placer le cercle au-dessus des autres éléments
      //   };
      // }

      if (annotation.type === "legalMove" && annotation.color) {
        styles[annotation.position] = {
          background: `radial-gradient(circle at center, ${annotation.color} 22%, transparent 23%)`,
          borderRadius: "50%",
        };
      }

      return styles;
    }, {});
  },
}));
