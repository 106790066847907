import { useUserStore } from '../store/useUserStore';

export function getPieceFromMove(
  san: string,
  history: string[]
): string | null {
  // Déterminer la couleur à partir de la longueur de l'historique
  const pieceColor = history.length % 2 === 0 ? "b" : "w";

  // Déterminer la pièce à partir de la première lettre de la notation SAN
  const pieceChar = san[0];

  // Si la première lettre est une case (a-h), c'est un pion
  if (/[a-h]/.test(pieceChar)) {
    return pieceColor + "P"; // Pion
  }

  // Sinon, on traite les autres pièces en fonction du caractère
  switch (pieceChar) {
    case "N":
      return pieceColor + "N"; // Cavalier
    case "B":
      return pieceColor + "B"; // Fou
    case "R":
      return pieceColor + "R"; // Tour
    case "Q":
      return pieceColor + "Q"; // Dame
    case "K":
      return pieceColor + "K"; // Roi
    default:
      return null; // Retourner null si la pièce n'est pas reconnue
  }
}

// Fonction pour télécharger un fichier JSON
export const downloadJsonFile = (data: object, filename: string) => {
  const fileData = JSON.stringify(data, null, 2);
  const blob = new Blob([fileData], { type: "application/json" });
  const url = URL.createObjectURL(blob);
  const link = document.createElement("a");
  link.href = url;
  link.download = `${filename}.json`;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

export const checkAccess = (currentMove: any): boolean => {
  const { isLogged, isAdmin } = useUserStore.getState(); // Get current user state

  const LOGIN_AFTER_MOVE = 5;
  const PREMIUM_AFTER_MOVE = 10;

  // Verify if the user needs to log in
  if (currentMove.path[0] > LOGIN_AFTER_MOVE && !isLogged) {
    return false; // Not logged in
  }

  // Verify if the user needs to be premium after a certain move
  // Replace 'isAdmin' with your own premium check if needed
  if (currentMove.path[0] > PREMIUM_AFTER_MOVE && !isAdmin) {
    return false; // Not premium
  }

  return true; // User is allowed
};
