import React from "react";
import { evaluation } from "../../../assets/svg/evaluation";
import { defaultPieces } from "../../../assets/svg/pieces";

import { useLessonOpeningStore } from "../../../store/useLessonOpeningStore";
import { useSiteSettingsStore } from "../../../store/useSiteSettingsStore";
import { Evaluation } from "../../../types";
import { getPieceFromMove } from "../../../utils/utils";
import "./MoveDetails.scss";

const MoveDetails: React.FC = () => {
  const { currentMove, getComment } = useLessonOpeningStore();
  const { language } = useSiteSettingsStore();
  // Utiliser le store zustand pour obtenir le mouvement actuel
  const pieces = defaultPieces({ width: "25px", height: "25px" });
  // Si aucun mouvement n'est fourni
  if (!currentMove) return <p>No moves available</p>;

  // Créer une instance de GameChess pour accéder à getPieceFromMove
  const piece = getPieceFromMove(currentMove.san, currentMove.history);

  return (
    <div className="moveDetails">
      <div className="moveDetails_title">
        {evaluation &&
          evaluation[currentMove.evaluation as keyof Evaluation] && (
            <div className="moveDetails_title_evaluation">
              {evaluation[currentMove.evaluation as keyof Evaluation]}
            </div>
          )}
        {currentMove.history.length > 0 && (
          <>
            {piece && pieces[piece] && (
              <div className="piece-icon">{pieces[piece]}</div>
            )}
            <strong>
              <h3 className="moveDetails_title_move">
                {currentMove.history.length + "."}
              </h3>
            </strong>
            <strong>
              <h3 className="moveDetails_title_move">{currentMove.san}</h3>
            </strong>
          </>
        )}
      </div>
      {/* Afficher l'icône de la pièce si disponible */}

      <span className="moveDetails_description">
        {getComment(currentMove.fen, language)}
      </span>
    </div>
  );
};

export default MoveDetails;
