import FacebookLogin from "@greatsumini/react-facebook-login";
import axios from "axios";
import icons from "../../../../assets/svg/icons";
import "./FacebookSignInButton.scss";
const API_URL = process.env.REACT_APP_API_URL || "";

function FacebookSignInButton() {
  const handleFacebookResponse = (response: any) => {
    const { accessToken } = response;

    axios.post(
      `${API_URL}/api/facebook-login`,
      { accessToken },
      { withCredentials: true }
    )
      .catch((err) => {
        console.error("Facebook login error:", err);
      });
  };

  return (
    <FacebookLogin
      appId={process.env.REACT_APP_FACEBOOK_APP_ID || ""}
      onSuccess={handleFacebookResponse}
      onFail={(error) => {
        console.error("Facebook login failed!", error);
      }}
      render={(renderProps: any) => (
        <button className="btn_Facebook" onClick={renderProps.onClick}>
          {icons.facebook}
          Continue with Facebook
        </button>
      )}
    />
  );
}

export default FacebookSignInButton;
