import { useEffect, useState } from "react";
import { FaArrowLeft } from "react-icons/fa";
import { HiOutlineLightBulb } from "react-icons/hi";
import { PiTreeStructureLight } from "react-icons/pi";
import { useModalTree } from "../../../../contexts/ModalTreeContext";

import { useLessonOpeningStore } from "../../../../store/useLessonOpeningStore";

import Tooltip from "../../Tooltip/Tooltip";
import "./TrainingPanel.scss";

const TrainingPanel = () => {
  const {
    handleUndoMove,
    handleNextMove,
    loadMoveWrapper,
    loadMove,
    currentMove,
    getOpeningTreeConverted,
    infoOpeningData,
  } = useLessonOpeningStore();
  const { setMode } = useLessonOpeningStore();
  const [isLightPressed, setIsLightPressed] = useState(false); // Variable pour suivre si le bouton Light a été pressé

  const {
    openModalTree,
    setTreeNode,
    setCurrentMove,
    setLoadMove,
    setTreeId,
    setColor,
    setLegende,
  } = useModalTree();

  // Fonction à exécuter lors de l'appui sur le bouton (clic ou appui tactile)
  const handleLightPressStart = (
    event: React.MouseEvent | React.TouchEvent
  ) => {
    event.preventDefault(); // Empêche le comportement par défaut comme le défilement
    setIsLightPressed(true); // Mettre à jour l'état pour indiquer que le bouton a été pressé
    handleNextMove();
  };

  // Fonction à exécuter lors du relâchement (clic ou appui tactile)
  const handleLightPressEnd = () => {
    if (isLightPressed) {
      setIsLightPressed(false); // Réinitialiser l'état après le relâchement
      handleUndoMove();
      handleUndoMove();
    }
  };

  useEffect(() => {
    // Ajout de l'écouteur global pour `onMouseUp` et `onTouchEnd`
    const handleGlobalRelease = () => {
      handleLightPressEnd();
    };

    // Écoute des événements `mouseup` et `touchend` au niveau du document
    document.addEventListener("mouseup", handleGlobalRelease);
    document.addEventListener("touchend", handleGlobalRelease);

    // Nettoyage de l'écouteur lors du démontage du composant
    return () => {
      document.removeEventListener("mouseup", handleGlobalRelease);
      document.removeEventListener("touchend", handleGlobalRelease);
    };
  }, [isLightPressed]);

  const handleModalTree = () => {
    const treeNode = getOpeningTreeConverted();
    const move = currentMove;

    setTreeNode(treeNode);
    setCurrentMove(move);
    setLoadMove(() => loadMoveWrapper); // Passe la référence de `loadMoveWrapper`
    setTreeId("modaltreecours");
    setLegende(null);
    setColor(infoOpeningData.color);
    // Ouvrir la modal
    openModalTree();
  };

  return (
    <div className="training-panel">
      <Tooltip text="Retour">
        <button
          type="button"
          aria-haspopup="dialog"
          className="training-panel_btn"
          onClick={() => {
            setMode("cours");
          }}
        >
          <div className="training-panel_btn_arrowLeft">
            <FaArrowLeft />
          </div>
        </button>
      </Tooltip>
      <div className="divider"></div>

      <Tooltip text="Arbre d'ouverture">
        <button
          type="button"
          className="learn-panel_btn"
          onClick={handleModalTree}
        >
          <div className="training-panel_btn_tree">
            <PiTreeStructureLight />
          </div>
        </button>
      </Tooltip>

      <Tooltip text="Montre le coup suivant">
        <button
          type="button"
          className="learn-panel_btn"
          onMouseDown={handleLightPressStart} // Détecte l'appui de la souris
          onTouchStart={handleLightPressStart} // Détecte l'appui tactile
        >
          <div className="training-panel_btn_light">
            <HiOutlineLightBulb />
          </div>
        </button>
      </Tooltip>
    </div>
  );
};

export default TrainingPanel;
