import { Square } from "chess.js";
import { useEffect, useState } from "react";
import { useAnnotationsStore } from "../../../store/useAnnotationsStore";
import { OpeningMove } from "../../../types";

import useCreateOpeningStore from "../../../store/useCreateOpeningStore";
import ChessboardManager from "../ChessboardManager/ChessboardManager";
import "./ChessboardCreation.scss";

export default function ChessboardCreation() {
  const { removeAllAnnotations, addAnnotation } = useAnnotationsStore();

  const {
    colorArrow,
    gameChess,
    currentMove,
    addMove,
    updateMove,

    infoOpeningData,
  } = useCreateOpeningStore();
  const [rightClickStartSquare, setRightClickStartSquare] = useState<
    string | null
  >(null);

  // useEffect pour réinitialiser le board au premier rendu
  useEffect(() => {
    removeAllAnnotations();
  }, []); // Le tableau de dépendances vide permet de n'exécuter cet effet qu'une seule fois

  const onDrop = (sourceSquare: string, targetSquare: string) => {
    const san = gameChess.getSANFromMove(sourceSquare, targetSquare);
    if (!san) return false;

    // Créer une nouvelle copie de l'historique pour éviter les mutations
    const history = [...(currentMove?.history || []), san];

    const newMoves: OpeningMove = {
      san: san,
      evaluation: "good",
      circles: [],
      history: history, // Utiliser la nouvelle copie de l'historique
      path: [],
      blockEntrainement: false,
      arrows: [],
      children: {},
      fen: gameChess.generateFENFromHistory(history) || "",
    };

    addMove(newMoves); // Ajout du coup dans l'ouverture ou renvoie le nœud existant

    return true;
  };

  // Fonction utilitaire pour trouver l'élément avec `data-square`
  const findSquareFromEvent = (e: MouseEvent): string | null => {
    let element = document.elementFromPoint(e.clientX, e.clientY);

    // Remonter l'arborescence DOM jusqu'à trouver un élément avec `data-square`
    while (element && !element.getAttribute("data-square")) {
      element = element.parentElement;
    }

    return element ? element.getAttribute("data-square") : null;
  };

  useEffect(() => {
    const handleMouseDown = (e: MouseEvent) => {
      if (e.button === 2) {
        // Vérifier si c'est un clic droit
        const square = findSquareFromEvent(e); // Trouver la case sous le clic
        if (square) {
          setRightClickStartSquare(square); // Enregistrer la case de départ
        }
      }
    };

    const handleMouseUp = (e: MouseEvent) => {
      if (e.button === 2 && rightClickStartSquare) {
        // Trouver la case sous le relâchement
        const square = findSquareFromEvent(e);

        if (square) {
          if (rightClickStartSquare === square) {
            console.log(`Cercle sur la case : ${square}`);
            updateMoveData("circle", square, null, colorArrow);
          } else {
            updateMoveData("arrow", rightClickStartSquare, square, colorArrow);
          }
        }
        setRightClickStartSquare(null); // Réinitialiser la case de départ
      }
    };

    const updateMoveData = (
      type: "arrow" | "circle",
      from: string,
      to: string | null,
      color: string
    ) => {
      if (!currentMove) return;

      let updatedData;

      if (type === "arrow") {
        updatedData = [
          ...(currentMove.arrows || []),
          [from as Square, to as Square, color],
        ];
      } else if (type === "circle") {
        updatedData = [...(currentMove.circles || []), [from as Square, color]];
      }

      const updatedMove: OpeningMove = {
        ...currentMove,
        [type === "arrow" ? "arrows" : "circles"]: updatedData,
      };

      updateMove(updatedMove);
    };

    window.addEventListener("mousedown", handleMouseDown);
    window.addEventListener("mouseup", handleMouseUp);

    return () => {
      window.removeEventListener("mousedown", handleMouseDown);
      window.removeEventListener("mouseup", handleMouseUp);
    };
  }, [rightClickStartSquare]);

  useEffect(() => {
    removeAllAnnotations();

    currentMove.circles.forEach(([square, type]) => {
      addAnnotation(square, "circle", type);
    });
  }, [currentMove]);

  return (
    <ChessboardManager
      gameChess={gameChess}
      customArrows={currentMove.arrows}
      arePiecesDraggable={true}
      onPieceDrop={onDrop}
      boardOrientation={infoOpeningData.color === "w" ? "white" : "black"}
      createArrow={false}
      resizable={false}
    />
  );
}
