// routes.js
import { createBrowserRouter } from "react-router-dom";
import { AdminAuthProvider } from "../contexts/AdminAuthContext";

import AccessDenied from "../pages/AccessDenied/AccessDenied";

import CreateOpening from "../pages/CreateOpening/CreateOpening";
import HomePage from "../pages/HomePage/HomePage";
import LearnTraining from "../pages/LearnTraining/LearnTraining";
import NoFound from "../pages/NoFound/NoFound";
import Test from "../pages/Test/Test";
import PaymentPage from "../components/Payment/PaymentPage";

const routerConfig = [
  {
    path: "/",
    element: <HomePage />,
  },
  {
    path: "/payment",
    element: <PaymentPage />, // Ajouter la route pour la page de paiement
  },
  {
    path: "opening/:openingPath",
    element: (
      <>
        <LearnTraining />
      </>
    ),
  },

  {
    path: "/createOpening",
    element: (
      <>
        <AdminAuthProvider>
          <CreateOpening />
        </AdminAuthProvider>
      </>
    ),
  },
  {
    path: "/test",
    element: (
      <>
        <Test />
      </>
    ),
  },
  {
    path: "/403",
    element: (
      <>
        <AccessDenied />
      </>
    ),
  },
  {
    path: "/*",
    element: (
      <>
        <NoFound />
      </>
    ),
  },
];

export const router = createBrowserRouter(routerConfig);
