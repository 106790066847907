import React, { ReactNode, useState } from "react";
import { toast } from "react-toastify";
import { config } from "../../../config";
import useCreateOpeningStore from "../../../store/useCreateOpeningStore";
import "./ClickSetting.scss"; // Importez le fichier CSS pour le style

// Définir une interface pour les props
interface ClickSettingProps {
  children: ReactNode; // Type pour les enfants
}

const ClickSetting: React.FC<ClickSettingProps> = ({ children }) => {
  const [isVisible, setIsVisible] = useState(false);
  const { resetStore } = useCreateOpeningStore();
  // Fonction pour afficher le menu
  const handleClick = () => {
    setIsVisible((prev) => !prev); // Inverse l'état de visibilité
  };

  // Fonction pour cacher le menu
  const handleMenuClose = () => {
    setIsVisible(false);
  };

  const handleConfirm = () => {
    localStorage.removeItem(config.localStorageCreateOpening);

    resetStore();
    toast.success("Effacer !");
  };

  const showConfirmationToast = () => {
    const notification = toast.warning(
      <div className="toast-content">
        <p>Voulez-vous continuer ?</p>
        <div>
          <button
            className="red-button"
            onClick={() => {
              handleConfirm();
              toast.dismiss(notification);
            }}
          >
            Effacer
          </button>
        </div>
      </div>,
      {
        autoClose: 10000, // Ferme automatiquement après 10 secondes
        closeOnClick: false,
        draggable: false,
        position: "bottom-right",
        theme: "colored",
      }
    );
  };

  return (
    <div className="click-setting-container">
      <div onClick={handleClick}>{children}</div>
      {isVisible && (
        <div className="click-setting" onMouseLeave={handleMenuClose}>
          <ul>
            <li onClick={showConfirmationToast}>Effacer l'ouverture</li>
            <li onClick={handleMenuClose}>Charger un cours</li>
          </ul>
        </div>
      )}
    </div>
  );
};

export default ClickSetting;
