import { saveAs } from "file-saver";
import { useEffect, useState } from "react";
import { IoSettingsSharp } from "react-icons/io5";
import { toast } from "react-toastify";
import { defaultPieces } from "../../../assets/svg/pieces";
import { useModalTree } from "../../../contexts/ModalTreeContext";
import useCreateOpeningStore from "../../../store/useCreateOpeningStore";
import { useSiteSettingsStore } from "../../../store/useSiteSettingsStore";
import { ChoiceEvaluation } from "../../../types";
import { getPieceFromMove } from "../../../utils/utils";
import Actions from "../../commun/Actions/Actions";
import HoverMenu from "../../commun/ClickSetting/ClickSetting";
import ColorSelector from "../../commun/ColorSelector/ColorSelector";
import CommentSection from "../../commun/CommentSection/CommentSection";
import MoveEvaluationSelector from "../../commun/MoveEvaluationSelector/MoveEvaluationSelector";
import LegendeCreateTree from "../../commun/OpeningTreeModal/LegendeCreateTree/LegendeCreateTree";
import "./OpeningEditor.scss";

export default function OpeningEditor() {
  const [activeButton, setActiveButton] = useState<string>("good");
  const [comment, setComment] = useState<string>("");
  const [isConfirmed, setIsConfirmed] = useState(false);

  const {
    currentMove,
    deleteMove,
    clearMove,
    updateMove,
    getComment,
    updateComment,
    getOpeningTreeConverted,
    getOpeningTree,
    pathIndex,
    comments,
    infoOpeningData,
  } = useCreateOpeningStore();
  const { language } = useSiteSettingsStore();
  const { setColorArrow, colorArrow, checkAllMovesHavePath } =
    useCreateOpeningStore();

  const {
    openModalTree,
    setTreeNode,
    setCurrentMove,
    setLoadMove,
    setTreeId,
    setLegende,
    setColor,
  } = useModalTree();

  const pieces = defaultPieces({ width: "25px", height: "25px" });
  const piece = getPieceFromMove(currentMove.san, currentMove.history);

  const handleButtonClick = (button: ChoiceEvaluation) => {
    const newMove = {
      ...currentMove,
      evaluation: button,
    };
    updateMove(newMove);
    setActiveButton(button);
  };

  useEffect(() => {
    if (currentMove.evaluation) {
      setActiveButton(currentMove.evaluation);
    } else {
      setActiveButton("good");
    }

    const comment = getComment(currentMove.fen, language);
    if (comment) {
      setComment(comment);
    } else {
      setComment("");
    }

    const treeNode = getOpeningTreeConverted();
    setTreeNode(treeNode);
  }, [currentMove]); // Assurez-vous d'inclure 'language' dans les dépendances si nécessaire

  const handleClear = () => {
    // Effacer le mouvement en utilisant la fonction clear du store
    clearMove(currentMove);
    setComment("");
    setActiveButton("good");
    updateComment("", language);
  };

  useEffect(() => {
    setTreeNode(getOpeningTreeConverted());
  }, [pathIndex]);

  const handleAddRoute = () => {
    setTreeNode(getOpeningTreeConverted());
    setCurrentMove(currentMove);
    setLoadMove(() => {});
    setTreeId("modaltreecreation");
    setLegende(<LegendeCreateTree />);
    setColor(infoOpeningData.color);
    // Ouvrir la modal
    openModalTree();
  };

  const handleDelete = () => {
    deleteMove(currentMove);
  };

  const handleColorSelect = (color: string) => {
    setColorArrow(color);
  };

  const handleCommentChange = (newComment: string) => {
    setComment(newComment);
    updateComment(newComment, language);
  };

  const handleSendServ = () => {
    if (checkAllMovesHavePath()) {
      console.log("Données envoyées au serveur :", currentMove);
      setIsConfirmed(false); // Réinitialiser la confirmation
      toast.success("Donnee envoyee au serveur !", {
        autoClose: 10000, // Ferme automatiquement après 3 secondes
        position: "top-right",
        theme: "colored",
      });
    } else {
      setIsConfirmed(false);
      toast.error(
        "Donnee non envoyee au serveur verifier que chaque mouvement est une page associer!",
        {
          autoClose: 10000, // Ferme automatiquement après 3 secondes
          position: "top-right",
          theme: "colored",
        }
      );
    }
  };

  const handleDownloadFiles = () => {
    const commentsBlob = new Blob([JSON.stringify(comments)], {
      type: "application/json",
    });
    const openingDataBlob = new Blob([JSON.stringify(getOpeningTree())], {
      type: "application/json",
    });
    const infoOpeningDataBlob = new Blob([JSON.stringify(infoOpeningData)], {
      type: "application/json",
    });

    saveAs(commentsBlob, `${infoOpeningData.nom}_comment.json`); // Téléchargement des commentaires
    saveAs(openingDataBlob, `${infoOpeningData.nom}_openingData.json`); // Téléchargement de l'ouverture
    saveAs(infoOpeningDataBlob, `${infoOpeningData.nom}_infoGeneral.json`); // Téléchargement des infos
  };

  return (
    <div className="pannel-create-opening">
      <div className="pannel-create-opening_setting">
        <HoverMenu>
          <IoSettingsSharp />
        </HoverMenu>
      </div>
      <div className="pannel-create-opening_mouvement">
        {piece && pieces[piece] && (
          <div className="piece-icon">{pieces[piece]}</div>
        )}
        <strong>
          <h3 className="moveDetails_title_move">
            {currentMove.history.length + "."}
          </h3>
        </strong>
        <strong>
          <h3 className="moveDetails_title_move">{currentMove.san}</h3>
        </strong>
      </div>
      <MoveEvaluationSelector
        activeButton={activeButton}
        onButtonClick={handleButtonClick}
      />
      <CommentSection comment={comment} onCommentChange={handleCommentChange} />
      <ColorSelector
        selectedColor={colorArrow}
        onColorSelect={handleColorSelect}
      />
      <Actions
        onClear={handleClear}
        onDelete={handleDelete}
        handleAddRoute={handleAddRoute}
        isConfirmed={isConfirmed}
        onConfirm={() => setIsConfirmed(true)}
        onCancel={() => setIsConfirmed(false)}
        onSendServ={handleSendServ}
        handleDownloadFiles={handleDownloadFiles}
      />
    </div>
  );
}
