import { useState } from "react";
import useCreateOpeningStore from "../../../store/useCreateOpeningStore";
import "./OpeningForm.scss";

export default function OpeningForm() {
  const { updateInfoOpeningData } = useCreateOpeningStore(); // Assurez-vous que cette méthode existe dans le store

  // États pour chaque champ du formulaire
  const [nom, setNom] = useState<string>("");
  const [createur, setCreateur] = useState<string>("");
  const [color, setColor] = useState<"w" | "b">("w");
  const [category, setCategory] = useState<string>("");
  const [level, setLevel] = useState<1 | 2 | 3>(1);
  const [commentaire, setCommentaire] = useState<string>("");

  // Fonction pour gérer la soumission du formulaire
  const handleSubmit = () => {
    updateInfoOpeningData({
      nom,
      createur: "asa",
      color,
      category: [category],
      level,
      nbrOfPath: 0,
      commentaire,
      history: [],
      path: "/",
    });
  };

  return (
    <div className="opening-info-form">
      <h3>Info ouverture</h3>

      {/* Titre de l'ouverture */}
      <label>
        Titre :
        <input
          type="text"
          value={nom}
          onChange={(e) => setNom(e.target.value)}
          placeholder="Titre de l'ouverture"
        />
      </label>

      {/* Couleur */}
      <label>
        Couleur :
        <select
          value={color}
          onChange={(e) => setColor(e.target.value as "w" | "b")}
        >
          <option value="w">Blanc</option>
          <option value="b">Noir</option>
        </select>
      </label>

      {/* Catégorie */}
      <label>
        Catégorie :
        <input
          type="text"
          value={category}
          onChange={(e) => setCategory(e.target.value)}
          placeholder="Catégorie de l'ouverture"
        />
      </label>

      {/* Niveau */}
      <label>
        Niveau :
        <select
          value={level}
          onChange={(e) => setLevel(Number(e.target.value) as 1 | 2 | 3)}
        >
          <option value={1}>Débutant</option>
          <option value={2}>Intermédiaire</option>
          <option value={3}>Avancé</option>
        </select>
      </label>

      {/* Commentaire */}
      <label>
        Commentaire :
        <textarea
          value={commentaire}
          onChange={(e) => setCommentaire(e.target.value)}
          placeholder="Ajouter un commentaire"
          rows={4}
        />
      </label>

      <button onClick={handleSubmit}>Confirmer</button>
    </div>
  );
}
