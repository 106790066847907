import React, { useEffect, useState } from "react";
import Modal from "react-modal";
import { config } from "react-spring";
import Carousel from "react-spring-3d-carousel";
import { useSwipeable } from "react-swipeable"; // Importation de react-swipeable
import { v4 as uuidv4 } from "uuid";
import { defaultPieces } from "../../assets/svg/pieces";
import PricingCard from "./PricingCard/PricingCard";
import "./PricingModal.scss";
const pieces = defaultPieces({ width: "100px", height: "100px" });

const pricingData = [
  {
    icon: pieces.wP,
    title: "ROAD TO NOOB",
    price: "5$",
    annualPlan: "/life",
    features: [
      "Un seul cours pour devenir moins noob, vite fait !",
      "Parfait pour poser les premières pierres de ta domination.Sur l'echequier",
    ],
    discount: 20, // Réduction si applicable
    endDate: new Date("2024-10-21"),
  },
  {
    icon: pieces.wQ,
    title: "ROAD TO GM",
    price: "144$",
    annualPlan: "/year",
    discount: 33, // Réduction sur le prix annuel
    endDate: new Date("2024-10-21"),
    features: [
      "Accès illimité, Rien que ça !",
      "Des nouveaux cours pour continuer à détruire tes adversaires.",
      "Une année complète de stratégie pour viser le sommet.",
    ],
  },
  {
    icon: pieces.wR,
    title: "ROAD TO MI",
    price: "12$",
    annualPlan: "/month",
    features: [
      "Accès illimité, mois après mois, pour toujours être au top.",
      "De nouveaux cours régulièrement, pour toujours progresser.",
      "Ton abonnement mensuel pour rester flexible et dangereux !",
    ],
  },
];

Modal.setAppElement("#root");

interface PricingModalProps {
  isOpen: boolean;
  closeModal: () => void;
}

const PricingModal: React.FC<PricingModalProps> = ({ isOpen, closeModal }) => {
  const [goToSlide, setGoToSlide] = useState<number>(0); // Pour naviguer vers une carte spécifique
  const [screenWidth, setScreenWidth] = useState<number>(window.innerWidth); // Pour suivre la largeur de l'écran

  const offsetRadius = 1;

  // Fonction pour changer la diapositive vers la carte cliquée
  const handleCardClick = (index: number) => {
    setGoToSlide(index); // Met le focus sur la carte cliquée
  };

  // Fonction pour gérer le swipe à gauche (aller à la prochaine carte)
  const handleSwipeLeft = () => {
    setGoToSlide((prevSlide) => (prevSlide + 1) % pricingData.length); // Passe à la carte suivante
  };

  // Fonction pour gérer le swipe à droite (aller à la carte précédente)
  const handleSwipeRight = () => {
    setGoToSlide((prevSlide) =>
      prevSlide === 0 ? pricingData.length - 1 : prevSlide - 1
    ); // Retourne à la carte précédente
  };

  // Fonction pour calculer la transformation complète en fonction de la taille de l'écran
  const calculateTransformation = (isLeft: boolean) => {
    const screenWidth = window.innerWidth;

    let translateX: number;
    let scale: number;

    if (screenWidth > 1200) {
      translateX = isLeft ? -70 : -30;
      scale = 0.75; // Échelle pour les grands écrans (desktop)
    } else if (screenWidth > 768) {
      translateX = isLeft ? -55 : -45;
      scale = 0.7; // Échelle pour les tablettes
    } else {
      translateX = isLeft ? -30 : -70;
      scale = 0.65; // Échelle pour les petits écrans (mobiles)
    }

    // Retourne la chaîne complète de transformation
    return `translateY(-40%) translateX(${translateX}%) scale(${scale})`;
  };

  // Utilisation du hook useEffect pour mettre à jour screenWidth à chaque redimensionnement de l'écran
  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };

    // Ajoute l'écouteur d'événement pour détecter le redimensionnement de la fenêtre
    window.addEventListener("resize", handleResize);

    // Nettoie l'écouteur d'événement lorsque le composant est démonté
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []); // Le tableau de dépendances vide signifie que cet effet n'est exécuté qu'une fois, au montage

  // Handlers pour le swipe
  const handlers = useSwipeable({
    onSwipedLeft: handleSwipeLeft,
    onSwipedRight: handleSwipeRight,
    trackMouse: true, // Pour permettre le swipe avec la souris aussi
  });

  const slides = pricingData.map((card, index) => ({
    key: uuidv4(),
    content: (
      <div onClick={() => handleCardClick(index)}>
        <PricingCard {...card} />
      </div>
    ),
  }));

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={closeModal}
      contentLabel="Pricing Modal"
      className="pricing-modal"
      overlayClassName="pricing-modal-overlay"
    >
      {/* Ajout des handlers de swipe ici */}
      <div {...handlers}>
        <Carousel
          slides={slides}
          goToSlide={goToSlide}
          offsetRadius={offsetRadius}
          showNavigation={false}
          animationConfig={config.wobbly}
          offsetFn={(offsetFromCenter) => {
            // Si offsetFromCenter est 0, l'élément est au centre
            if (offsetFromCenter === 0) {
              return {
                opacity: 1, // Pleine opacité pour l'élément central
                transform: "translateY(-50%) translateX(-50%) scale(1)",
                left: "50%", // L'élément reste au centre avec une échelle normale
              };
            }

            // Si l'offset est négatif, l'élément est à gauche, sinon à droite
            const isLeft = offsetFromCenter < 0;

            return {
              opacity: 0.9,
              transform: calculateTransformation(isLeft), // Appel à la fonction pour gérer la transformation
            };
          }}
        />
      </div>
    </Modal>
  );
};

export default PricingModal;
