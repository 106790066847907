import { ReactNode } from "react";
// https://commons.wikimedia.org/wiki/Category:SVG_chess_pieces
// By en:User:Cburnett - Own work
// This W3C - unspecified vector image was created with Inkscape., CC BY - SA 3.0, https://commons.wikimedia.org/w/index.php?curid=1499810

export const icons: Record<string, ReactNode> = {
  stop: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="14"
      height="14"
      viewBox="0 0 48 48"
    >
      <g id="Layer_2" data-name="Layer 2">
        <g id="invisible_box" data-name="invisible box">
          <rect width="48" height="48" fill="none" />
        </g>
        <g id="icons_Q2" data-name="icons Q2">
          <path
            fill="red"
            d="M43.4,15.1,32.9,4.6A2,2,0,0,0,31.5,4h-15a2,2,0,0,0-1.4.6L4.6,15.1A2,2,0,0,0,4,16.5v15a2,2,0,0,0,.6,1.4L15.1,43.4a2,2,0,0,0,1.4.6h15a2,2,0,0,0,1.4-.6L43.4,32.9a2,2,0,0,0,.6-1.4v-15A2,2,0,0,0,43.4,15.1Z"
          />

          <path
            fill="white"
            d="M32.4,29.6a1.9,1.9,0,0,1,0,2.8,1.9,1.9,0,0,1-2.8,0L24,26.8l-5.6,5.6a1.9,1.9,0,0,1-2.8,0,1.9,1.9,0,0,1,0-2.8L21.2,24l-5.6-5.6a2,2,0,0,1,2.8-2.8L24,21.2l5.6-5.6a2,2,0,0,1,2.8,2.8L26.8,24Z"
          />
        </g>
      </g>
    </svg>
  ),
  open: (
    <svg
      className="icon_open"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      width="20"
      height="20"
      viewBox="0 0 128 128"
    >
      <path d="M 84 11 C 82.3 11 81 12.3 81 14 C 81 15.7 82.3 17 84 17 L 106.80078 17 L 60.400391 63.400391 C 59.200391 64.600391 59.200391 66.499609 60.400391 67.599609 C 61.000391 68.199609 61.8 68.5 62.5 68.5 C 63.2 68.5 63.999609 68.199609 64.599609 67.599609 L 111 21.199219 L 111 44 C 111 45.7 112.3 47 114 47 C 115.7 47 117 45.7 117 44 L 117 14 C 117 12.3 115.7 11 114 11 L 84 11 z M 24 31 C 16.8 31 11 36.8 11 44 L 11 104 C 11 111.2 16.8 117 24 117 L 84 117 C 91.2 117 97 111.2 97 104 L 97 59 C 97 57.3 95.7 56 94 56 C 92.3 56 91 57.3 91 59 L 91 104 C 91 107.9 87.9 111 84 111 L 24 111 C 20.1 111 17 107.9 17 104 L 17 44 C 17 40.1 20.1 37 24 37 L 69 37 C 70.7 37 72 35.7 72 34 C 72 32.3 70.7 31 69 31 L 24 31 z"></path>
    </svg>
  ),
  facebook: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="#FFFFFF"
      height="18px"
      width="18px"
      version="1.1"
      id="Layer_1"
      viewBox="0 0 310 310"
    >
      <g id="XMLID_834_">
        <path
          id="XMLID_835_"
          d="M81.703,165.106h33.981V305c0,2.762,2.238,5,5,5h57.616c2.762,0,5-2.238,5-5V165.765h39.064   c2.54,0,4.677-1.906,4.967-4.429l5.933-51.502c0.163-1.417-0.286-2.836-1.234-3.899c-0.949-1.064-2.307-1.673-3.732-1.673h-44.996   V71.978c0-9.732,5.24-14.667,15.576-14.667c1.473,0,29.42,0,29.42,0c2.762,0,5-2.239,5-5V5.037c0-2.762-2.238-5-5-5h-40.545   C187.467,0.023,186.832,0,185.896,0c-7.035,0-31.488,1.381-50.804,19.151c-21.402,19.692-18.427,43.27-17.716,47.358v37.752H81.703   c-2.762,0-5,2.238-5,5v50.844C76.703,162.867,78.941,165.106,81.703,165.106z"
        />
      </g>
    </svg>
  ),

  disconnect: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height="20px"
      width="20px"
      version="1.1"
      id="Layer_1"
      viewBox="0 0 330 330"
    >
      <g id="XMLID_2_">
        <path
          id="XMLID_4_"
          d="M51.213,180h173.785c8.284,0,15-6.716,15-15s-6.716-15-15-15H51.213l19.394-19.393   c5.858-5.857,5.858-15.355,0-21.213c-5.856-5.858-15.354-5.858-21.213,0L4.397,154.391c-0.348,0.347-0.676,0.71-0.988,1.09   c-0.076,0.093-0.141,0.193-0.215,0.288c-0.229,0.291-0.454,0.583-0.66,0.891c-0.06,0.09-0.109,0.185-0.168,0.276   c-0.206,0.322-0.408,0.647-0.59,0.986c-0.035,0.067-0.064,0.138-0.099,0.205c-0.189,0.367-0.371,0.739-0.53,1.123   c-0.02,0.047-0.034,0.097-0.053,0.145c-0.163,0.404-0.314,0.813-0.442,1.234c-0.017,0.053-0.026,0.108-0.041,0.162   c-0.121,0.413-0.232,0.83-0.317,1.257c-0.025,0.127-0.036,0.258-0.059,0.386c-0.062,0.354-0.124,0.708-0.159,1.069   C0.025,163.998,0,164.498,0,165s0.025,1.002,0.076,1.498c0.035,0.366,0.099,0.723,0.16,1.08c0.022,0.124,0.033,0.251,0.058,0.374   c0.086,0.431,0.196,0.852,0.318,1.269c0.015,0.049,0.024,0.101,0.039,0.15c0.129,0.423,0.28,0.836,0.445,1.244   c0.018,0.044,0.031,0.091,0.05,0.135c0.16,0.387,0.343,0.761,0.534,1.13c0.033,0.065,0.061,0.133,0.095,0.198   c0.184,0.341,0.387,0.669,0.596,0.994c0.056,0.088,0.104,0.181,0.162,0.267c0.207,0.309,0.434,0.603,0.662,0.895   c0.073,0.094,0.138,0.193,0.213,0.285c0.313,0.379,0.641,0.743,0.988,1.09l44.997,44.997C52.322,223.536,56.161,225,60,225   s7.678-1.464,10.606-4.394c5.858-5.858,5.858-15.355,0-21.213L51.213,180z"
        />
        <path
          id="XMLID_5_"
          d="M207.299,42.299c-40.944,0-79.038,20.312-101.903,54.333c-4.62,6.875-2.792,16.195,4.083,20.816   c6.876,4.62,16.195,2.794,20.817-4.083c17.281-25.715,46.067-41.067,77.003-41.067C258.414,72.299,300,113.884,300,165   s-41.586,92.701-92.701,92.701c-30.845,0-59.584-15.283-76.878-40.881c-4.639-6.865-13.961-8.669-20.827-4.032   c-6.864,4.638-8.67,13.962-4.032,20.826c22.881,33.868,60.913,54.087,101.737,54.087C274.956,287.701,330,232.658,330,165   S274.956,42.299,207.299,42.299z"
        />
      </g>
    </svg>
  ),
  login: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height="20px"
      width="20px"
      version="1.1"
      id="Layer_1"
      viewBox="0 0 330.002 330.002"
    >
      <g id="XMLID_9_">
        <path
          id="XMLID_10_"
          d="M169.392,199.395c-5.858,5.857-5.858,15.355,0,21.213c2.929,2.929,6.767,4.394,10.607,4.394   c3.838,0,7.678-1.465,10.606-4.394l44.998-44.997c0.347-0.347,0.676-0.712,0.988-1.091c0.069-0.084,0.128-0.176,0.196-0.262   c0.235-0.299,0.467-0.6,0.68-0.917c0.055-0.083,0.101-0.171,0.154-0.254c0.211-0.329,0.418-0.662,0.603-1.007   c0.032-0.06,0.057-0.123,0.088-0.184c0.194-0.374,0.378-0.753,0.541-1.145c0.017-0.04,0.028-0.081,0.044-0.121   c0.167-0.411,0.32-0.829,0.45-1.258c0.014-0.046,0.022-0.094,0.036-0.14c0.123-0.419,0.235-0.844,0.321-1.278   c0.024-0.121,0.035-0.245,0.056-0.367c0.063-0.36,0.125-0.72,0.162-1.088c0.05-0.496,0.076-0.995,0.076-1.497   c0-0.503-0.026-1.002-0.076-1.497c-0.037-0.371-0.1-0.734-0.164-1.097c-0.021-0.119-0.031-0.24-0.055-0.358   c-0.087-0.437-0.199-0.864-0.323-1.286c-0.013-0.044-0.02-0.088-0.034-0.132c-0.131-0.432-0.286-0.852-0.454-1.267   c-0.015-0.036-0.025-0.075-0.041-0.111c-0.164-0.394-0.349-0.776-0.544-1.152c-0.03-0.058-0.054-0.119-0.085-0.176   c-0.187-0.348-0.394-0.682-0.606-1.013c-0.053-0.082-0.097-0.168-0.151-0.249c-0.213-0.317-0.445-0.62-0.681-0.919   c-0.067-0.086-0.126-0.177-0.195-0.261c-0.312-0.379-0.641-0.744-0.988-1.091l-44.998-44.998c-5.857-5.858-15.355-5.858-21.213,0   c-5.858,5.858-5.858,15.355,0,21.213l19.393,19.394H15c-8.284,0-15,6.716-15,15s6.716,15,15,15h173.785L169.392,199.395z"
        />
        <path
          id="XMLID_11_"
          d="M207.301,42.3c-40.945,0-79.04,20.312-101.903,54.333c-4.621,6.876-2.793,16.196,4.083,20.816   c6.876,4.621,16.196,2.793,20.816-4.083C147.578,87.652,176.365,72.3,207.301,72.3c51.116,0,92.701,41.586,92.701,92.701   s-41.586,92.701-92.701,92.701c-30.844,0-59.585-15.283-76.879-40.882c-4.638-6.864-13.962-8.67-20.827-4.032   c-6.864,4.638-8.67,13.962-4.032,20.827c22.882,33.868,60.915,54.087,101.738,54.087c67.658,0,122.701-55.044,122.701-122.701   S274.958,42.3,207.301,42.3z"
        />
      </g>
    </svg>
  ),
};

export default icons;
