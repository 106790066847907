import { FaArrowLeft, FaArrowRight } from "react-icons/fa";
import { IoMdFitness } from "react-icons/io";
import { PiGraduationCap, PiTreeStructureLight } from "react-icons/pi";
import { config } from "../../../../config";
import { useLogin } from "../../../../contexts/checkLoginAndModal";
import { useModalTree } from "../../../../contexts/ModalTreeContext";
import { useModalPricing } from "../../../../contexts/PrincingModalContext";
import { useLessonOpeningStore } from "../../../../store/useLessonOpeningStore";
import Tooltip from "../../Tooltip/Tooltip";
import "./LearnPanel.scss";
// Typage pour la fonction de changement de mode

const LearnPanel = () => {
  const { checkLoginAndModal } = useLogin();
  const { checkPremiumAndOpenModal } = useModalPricing();
  const {
    currentMove,
    handleUndoMove,
    handleNextMove,
    loadMoveWrapper,
    getOpeningTreeConverted,
    infoOpeningData,
  } = useLessonOpeningStore();

  const { setMode } = useLessonOpeningStore();
  const {
    openModalTree,
    setTreeNode,
    setCurrentMove,
    setLoadMove,
    setTreeId,
    setColor,
    setLegende,
  } = useModalTree();

  const executeActionWithLoginCheck = async (nbrNove: number) => {
    if (currentMove.path[0] >= nbrNove) {
      const isLoggedIn = await checkLoginAndModal();
      if (!isLoggedIn) {
        return false;
      }
    }

    return true;
  };

  const processUndoMove = async () => {
    const result = await executeActionWithLoginCheck(
      config.nbrMoveForConnect + 1
    );
    if (result) {
      if (currentMove.path[0] >= config.nbrMoveForPremium) {
        const isPremium = checkPremiumAndOpenModal();
        if (!isPremium) {
          return;
        }
      }
    }
    handleUndoMove();
  };

  const handleModalTree = () => {
    const treeNode = getOpeningTreeConverted();
    const move = currentMove;

    setTreeNode(treeNode);
    setCurrentMove(move);
    setLoadMove(() => loadMoveWrapper); // Passe la référence de `loadMoveWrapper`
    setTreeId("modaltreecours");
    setLegende(null);
    setColor(infoOpeningData.color);
    openModalTree();
  };

  const processNextMove = async () => {
    const result = await executeActionWithLoginCheck(
      config.nbrMoveForConnect + 1
    );
    if (result) {
      if (currentMove.path[0] >= config.nbrMoveForPremium) {
        const isPremium = checkPremiumAndOpenModal();
        if (!isPremium) {
          return;
        }
      }
    }
    handleNextMove();
  };

  return (
    <div className="learn-panel">
      <button
        type="button"
        aria-haspopup="dialog"
        className="learn-panel_btn"
        onClick={processUndoMove}
      >
        <div className="learn-panel_btn_arrowLeft">
          <FaArrowLeft />
        </div>
      </button>

      <button
        type="button"
        aria-haspopup="dialog"
        className="learn-panel_btn"
        onClick={processNextMove}
      >
        <div className="learn-panel_btn_arrowRight">
          <FaArrowRight />
        </div>
      </button>

      <div className="divider"></div>
      <Tooltip text="Arbre d'ouverture">
        <button
          type="button"
          className="learn-panel_btn"
          onClick={() => {
            handleModalTree();
          }}
        >
          <div className="training-panel_btn_tree">
            <PiTreeStructureLight />
          </div>
        </button>
      </Tooltip>

      <Tooltip text="Mode entrainement">
        <button
          type="button"
          aria-haspopup="dialog"
          aria-expanded="false"
          aria-controls="radix-:r4c:"
          className="learn-panel_btn"
          onClick={() => {
            setMode("entrainement");
          }}
        >
          <div className="learn-panel_btn_training">
            <IoMdFitness />
          </div>
        </button>
      </Tooltip>

      <Tooltip text="Mode evaluation">
        <button
          type="button"
          aria-haspopup="dialog"
          aria-expanded="false"
          aria-controls="radix-:r4c:"
          className="learn-panel_btn"
          onClick={() => {
            setMode("graduation");
          }}
        >
          <div className="learn-panel_btn_graduation">
            <PiGraduationCap />
          </div>
        </button>
      </Tooltip>
    </div>
  );
};

export default LearnPanel;
