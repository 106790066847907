// EmailSignInForm.tsx

import axios from 'axios';
import React, { useState } from 'react';
import { useUserStore } from '../../../../store/useUserStore';
import './EmailSignInForm.scss';

const API_URL = process.env.REACT_APP_API_URL || '';

const EmailSignInForm: React.FC = () => {
  const [tabValue, setTabValue] = useState(0);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const { checkLoginStatus } = useUserStore();

  const isSignUp = tabValue === 1;

  const handleTabChange = (newValue: number) => {
    setTabValue(newValue);
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      const endpoint = isSignUp ? '/api/signup' : '/api/login';
      await axios.post(
        `${API_URL}${endpoint}`,
        {
          email,
          password,
        },
        {
          withCredentials: true, // Allow cookies to be sent with requests
        }
      );
      // Update application state
      await checkLoginStatus();
    } catch (error) {
      console.error('Authentication error:', error);
      alert('Authentication failed. Please try again.');
    }
  };

  return (
    <div className="email-sign-in-form">
      <div className="tabs">
        <button
          className={`tab ${tabValue === 0 ? 'active' : ''}`}
          onClick={() => handleTabChange(0)}
        >
          Sign In
        </button>
        <button
          className={`tab ${tabValue === 1 ? 'active' : ''}`}
          onClick={() => handleTabChange(1)}
        >
          Sign Up
        </button>
      </div>
      <form onSubmit={handleSubmit} className="form">
        <input
          type="email"
          placeholder="Email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
          className="input"
        />
        <input
          type="password"
          placeholder="Password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          required
          className="input"
        />
        <button type="submit" className="submit-button">
          {isSignUp ? 'Sign Up' : 'Sign In'}
        </button>
      </form>
    </div>
  );
};

export default EmailSignInForm;
