import "./CommentSection.scss";

interface CommentSectionProps {
  comment: string;
  onCommentChange: (comment: string) => void;
}

export default function CommentSection({
  comment,
  onCommentChange,
}: CommentSectionProps) {
  return (
    <div className="comment-section">
      <textarea
        id="comment"
        value={comment}
        placeholder="Ajouter un commentaire"
        onChange={(e) => onCommentChange(e.target.value)}
      />
    </div>
  );
}
